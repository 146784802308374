export const DRAGON_BELT_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "60%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "35%",
  },
  {
    "Value": "+5",
    "Probability": "30%",
  },
  {
    "Value": "+6",
    "Probability": "28%",
  },
  {
    "Value": "+7",
    "Probability": "24%",
  },
  {
    "Value": "+8",
    "Probability": "26%",
  },
  {
    "Value": "+9",
    "Probability": "22%",
  },
  {
    "Value": "+10",
    "Probability": "20%",
  },
]

export const DRAGON_BELT_V1 = [
  {
    "Weight limit": "+1000",
    "Inventory slots": "-",
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "-",
    "DEX/ WIT": "+1",
    "CON/ MEN": "-",
  },
  {
    "Weight limit": "+2000",
    "Inventory slots": "+2",
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "-",
    "DEX/ WIT": "+1",
    "CON/ MEN": "+1",
  },
  {
    "Weight limit": "+3000",
    "Inventory slots": "+4",
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+1",
    "DEX/ WIT": "+1",
    "CON/ MEN": "+1",
  },
  {
    "Weight limit": "+4000",
    "Inventory slots": "+6",
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+2",
    "DEX/ WIT": "+2",
    "CON/ MEN": "+2",
  },
  {
    "Weight limit": "+5000",
    "Inventory slots": "+6",
    "Max HP": "+100",
    "Max MP": "+50",
    "Max CP": "+100",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+3",
    "DEX/ WIT": "+3",
    "CON/ MEN": "+3",
  },
  {
    "Weight limit": "+6000",
    "Inventory slots": "+6",
    "Max HP": "+150",
    "Max MP": "+100",
    "Max CP": "+200",
    "HP Recovery Potions' Effect": "+80",
    "MP Recovery Potions' Effect": "+10",
    "P. Def.": "+30",
    "M. Def.": "+20",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+3",
    "DEX/ WIT": "+3",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+7000",
    "Inventory slots": "+6",
    "Max HP": "+200",
    "Max MP": "+150",
    "Max CP": "+300",
    "HP Recovery Potions' Effect": "+100",
    "MP Recovery Potions' Effect": "+20",
    "P. Def.": "+40",
    "M. Def.": "+30",
    "Atk. Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+3",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+8000",
    "Inventory slots": "+8",
    "Max HP": "+300",
    "Max MP": "+200",
    "Max CP": "+500",
    "HP Recovery Potions' Effect": "+120",
    "MP Recovery Potions' Effect": "+30",
    "P. Def.": "+50",
    "M. Def.": "+40",
    "Atk. Spd.": "+20",
    "P. Critical Rate/ M. Skill Critical Rate": "+10",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+4",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+9000",
    "Inventory slots": "+8",
    "Max HP": "+400",
    "Max MP": "+250",
    "Max CP": "+700",
    "HP Recovery Potions' Effect": "+150",
    "MP Recovery Potions' Effect": "+50",
    "P. Def.": "+80",
    "M. Def.": "+50",
    "Atk. Spd.": "+30",
    "P. Critical Rate/ M. Skill Critical Rate": "+20",
    "Received Healing": "+5%",
    "Received damage": "-3%",
    "STR/ INT": "+4",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+5",
  },
  {
    "Weight limit": "+10000",
    "Inventory slots": "+10",
    "Max HP": "+600",
    "Max MP": "+300",
    "Max CP": "+1000",
    "HP Recovery Potions' Effect": "+200",
    "MP Recovery Potions' Effect": "+80",
    "P. Def.": "+100",
    "M. Def.": "+80",
    "Atk. Spd.": "+50",
    "P. Critical Rate/ M. Skill Critical Rate": "+30",
    "Received Healing": "+10%",
    "Received damage": "-5%",
    "STR/ INT": "+4",
    "DEX/ WIT": "+5",
    "CON/ MEN": "+5",
  },
  {
    "Weight limit": "+12000",
    "Inventory slots": "+12",
    "Max HP": "+1000",
    "Max MP": "+500",
    "Max CP": "+1500",
    "HP Recovery Potions' Effect": "+300",
    "MP Recovery Potions' Effect": "+100",
    "P. Def.": "+150",
    "M. Def.": "+120",
    "Atk. Spd.": "+100",
    "P. Critical Rate/ M. Skill Critical Rate": "+50",
    "Received Healing": "+20%",
    "Received damage": "-10%",
    "STR/ INT": "+5",
    "DEX/ WIT": "+5",
    "CON/ MEN": "+5",
  }
]