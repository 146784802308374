export const QUEEN_ANTS_STONE_BREAKER_ENCHANT_V1 = [ 
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "100%",
  },
  {
    "Value": "+3",
    "Probability": "100%",
  },
  {
    "Value": "+4",
    "Probability": "100%",
  },
  {
    "Value": "+5",
    "Probability": "33%",
  },
  {
    "Value": "+6",
    "Probability": "15%",
  },
  {
    "Value": "+7",
    "Probability": "15%",
  },
  {
    "Value": "+8",
    "Probability": "8%",
  },
  {
    "Value": "+9",
    "Probability": "4%",
  },
  {
    "Value": "+10",
    "Probability": "2%",
  },
]

export const QUEEN_ANTS_STONE_BREAKER_V1 = [ 
  {
    "P. Atk.": "271",
    "M. Atk.": "182",
    "Stat bonus": "-",
  },
  {
    "P. Atk.": "281",
    "M. Atk.": "188",
    "Stat bonus": "-",
  },
  {
    "P. Atk.": "291",
    "M. Atk.": "194",
    "Stat bonus": "-",
  },
  {
    "P. Atk.": "301",
    "M. Atk.": "200",
    "Stat bonus": "-",
  },
  {
    "P. Atk.": "331",
    "M. Atk.": "218",
    "Stat bonus": "-",
  },
  {
    "P. Atk.": "361",
    "M. Atk.": "236",
    "Stat bonus": "DEX/WIT +1",
  },
  {
    "P. Atk.": "391",
    "M. Atk.": "254",
    "Stat bonus": "DEX/WIT +2",
  },
  {
    "P. Atk.": "421",
    "M. Atk.": "272",
    "Stat bonus": "DEX/WIT +3",
  },
  {
    "P. Atk.": "451",
    "M. Atk.": "290",
    "Stat bonus": "DEX/WIT +4",
  },
  {
    "P. Atk.": "481",
    "M. Atk.": "308",
    "Stat bonus": "DEX/WIT +5",
  },
  {
    "P. Atk.": "511",
    "M. Atk.": "326",
    "Stat bonus": "DEX/WIT +6",
  },
]

export const QUEEN_ANTS_STONE_BREAKER_TRIGGER_V1 = [
  {
    "Probability": "1%",
    "Power": 72,
    "Probability_P": "1%",
    "Power_P": 72,
    "Probability_M": "1%",
    "Power_M": 43,
  },
  {
    "Probability": "1%",
    "Power": 73,
    "Probability_P": "1%",
    "Power_P": 73,
    "Probability_M": "1%",
    "Power_M": 44,
  },
  {
    "Probability": "1%",
    "Power": 74,
    "Probability_P": "1%",
    "Power_P": 74,
    "Probability_M": "1%",
    "Power_M": 45,
  },
  {
    "Probability": "1%",
    "Power": 76,
    "Probability_P": "1%",
    "Power_P": 76,
    "Probability_M": "1%",
    "Power_M": 47,
  },
  {
    "Probability": "1%",
    "Power": 78,
    "Probability_P": "1%",
    "Power_P": 78,
    "Probability_M": "1%",
    "Power_M": 48,
  },
  {
    "Probability": "2%",
    "Power": 81,
    "Probability_P": "4%",
    "Power_P": 81,
    "Probability_M": "4%",
    "Power_M": 50,
  },
  {
    "Probability": "4%",
    "Power": 84,
    "Probability_P": "7%",
    "Power_P": 84,
    "Probability_M": "7%",
    "Power_M": 52,
  },
  {
    "Probability": "6%",
    "Power": 86,
    "Probability_P": "10%",
    "Power_P": 86,
    "Probability_M": "10%",
    "Power_M": 53,
  },
  {
    "Probability": "8%",
    "Power": 89,
    "Probability_P": "13%",
    "Power_P": 89,
    "Probability_M": "13%",
    "Power_M": 55,
  },
  {
    "Probability": "10%",
    "Power": 92,
    "Probability_P": "16%",
    "Power_P": 92,
    "Probability_M": "16%",
    "Power_M": 57,
  },
  {
    "Probability": "12%",
    "Power": 95,
    "Probability_P": "20%",
    "Power_P": 95,
    "Probability_M": "20%",
    "Power_M": 43,
  },
]

export const QUEEN_ANTS_STONE_BREAKER_TRIGGER2_V1 = [
  {
    "Probability": "-",
    "Power": "-",
    "Probability_P": "-",
    "Power_P": "-",
    "Probability_M": "-",
    "Power_M": "-",
  },
  {
    "Probability": "-",
    "Power": "-",
    "Probability_P": "-",
    "Power_P": "-",
    "Probability_M": "-",
    "Power_M": "-",
  },
  {
    "Probability": "-",
    "Power": "-",
    "Probability_P": "-",
    "Power_P": "-",
    "Probability_M": "-",
    "Power_M": "-",
  },
  {
    "Probability": "-",
    "Power": "-",
    "Probability_P": "-",
    "Power_P": "-",
    "Probability_M": "-",
    "Power_M": "-",
  },
  {
    "Probability": "-",
    "Power": "-",
    "Probability_P": "-",
    "Power_P": "-",
    "Probability_M": "-",
    "Power_M": "-",
  },
  {
    "Probability": "1%",
    "Power": 121,
    "Probability_P": "1%",
    "Power_P": 121,
    "Probability_M": "1%",
    "Power_M": 73,
  },
  {
    "Probability": "1%",
    "Power": 126,
    "Probability_P": "1%",
    "Power_P": 126,
    "Probability_M": "1%",
    "Power_M": 75,
  },
  {
    "Probability": "1%",
    "Power": 130,
    "Probability_P": "2%",
    "Power_P": 130,
    "Probability_M": "2%",
    "Power_M": 53,
  },
  {
    "Probability": "2%",
    "Power": 134,
    "Probability_P": "3%",
    "Power_P": 134,
    "Probability_M": "3%",
    "Power_M": 80,
  },
  {
    "Probability": "2%",
    "Power": 138,
    "Probability_P": "4%",
    "Power_P": 138,
    "Probability_M": "4%",
    "Power_M": 83,
  },
  {
    "Probability": "3%",
    "Power": 142,
    "Probability_P": "5%",
    "Power_P": 142,
    "Probability_M": "5%",
    "Power_M": 85,
  },
]