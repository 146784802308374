export const ZAKENS_EARRING_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const ZAKENS_EARRING_V1 = [
  {
    "Max MP": "+22",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+4%",
    "Skill MP Consumption": "-2%",
    "HP Recovery Rate": "+10%",
    "HP Vampiric": "-",
    "HP Recovery Potions' Effect": "-",
    "Infection Atk. Rate": "+10%",
    "Shock Atk. Rate": "+5%",
    "Silence Atk. Rate": "+5%",
    "Fear Atk. Rate": "+5%",
    "Infection Resistance": "+10%",
    "Shock Resistance": "+5%",
    "Silence Resistance": "+5%",
    "Fear Resistance": "+5%",
  },
  {
    "Max MP": "+28",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+6%",
    "Skill MP Consumption": "-3%",
    "HP Recovery Rate": "+15%",
    "HP Vampiric": "-",
    "HP Recovery Potions' Effect": "-",
    "Infection Atk. Rate": "+15%",
    "Shock Atk. Rate": "+10%",
    "Silence Atk. Rate": "+10%",
    "Fear Atk. Rate": "+10%",
    "Infection Resistance": "+15%",
    "Shock Resistance": "+10%",
    "Silence Resistance": "+10%",
    "Fear Resistance": "+10%",
  },
  {
    "Max MP": "+34",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+10%",
    "Skill MP Consumption": "-4%",
    "HP Recovery Rate": "+20%",
    "HP Vampiric": "Absorbs 8% of the inflicted damage as HP.",
    "HP Recovery Potions' Effect": "-",
    "Infection Atk. Rate": "+20%",
    "Shock Atk. Rate": "+15%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Infection Resistance": "+20%",
    "Shock Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+45",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Received Healing": "+12%",
    "Skill MP Consumption": "-5%",
    "HP Recovery Rate": "+20%",
    "HP Vampiric": "Absorbs 8% of the inflicted damage as HP.",
    "HP Recovery Potions' Effect": "+50",
    "Infection Atk. Rate": "+20%",
    "Shock Atk. Rate": "+15%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Infection Resistance": "+20%",
    "Shock Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+60",
    "P. Atk.": "+100",
    "M. Atk.": "+100",
    "P. Def.": "+250",
    "M. Def.": "+250",
    "Received Healing": "+15%",
    "Skill MP Consumption": "-6%",
    "HP Recovery Rate": "+20%",
    "HP Vampiric": "Absorbs 8% of the inflicted damage as HP.",
    "HP Recovery Potions' Effect": "+100",
    "Infection Atk. Rate": "+20%",
    "Shock Atk. Rate": "+15%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Infection Resistance": "+20%",
    "Shock Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+90",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "P. Def.": "+350",
    "M. Def.": "+350",
    "Received Healing": "+20%",
    "Skill MP Consumption": "-8%",
    "HP Recovery Rate": "+20%",
    "HP Vampiric": "Absorbs 8% of the inflicted damage as HP.",
    "HP Recovery Potions' Effect": "+200",
    "Infection Atk. Rate": "+20%",
    "Shock Atk. Rate": "+15%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Infection Resistance": "+20%",
    "Shock Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+120",
    "P. Atk.": "+800",
    "M. Atk.": "+800",
    "P. Def.": "+800",
    "M. Def.": "+800",
    "Received Healing": "+20%",
    "Skill MP Consumption": "-10%",
    "HP Recovery Rate": "+20%",
    "HP Vampiric": "Absorbs 8% of the inflicted damage as HP.",
    "HP Recovery Potions' Effect": "+250",
    "Infection Atk. Rate": "+20%",
    "Shock Atk. Rate": "+20%",
    "Silence Atk. Rate": "+20%",
    "Fear Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Shock Resistance": "+20%",
    "Silence Resistance": "+20%",
    "Fear Resistance": "+20%",
  },
]

export const ZAKENS_EARRING_TRIGGER_V1 = [
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "Basic Attack: 50% / Skill: 50%",
  "Effect": "Recovers 10% of HP.",
  "Cooldown": "5 min.",
},
{
  "Probability": "Basic Attack: 50% / Skill: 50%",
  "Effect": "Recovers 10% of HP.",
  "Cooldown": "5 min.",
},
]

export const ZAKENS_EARRING_TRIGGER2_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "Basic Attack: 1% / Skill: 1%",
    "Effect": "Inflicts Silence on the enemy.",
    "Duration": "3 sec.",
  },
  {
    "Probability": "Basic Attack: 1% / Skill: 1%",
    "Effect": "Inflicts Silence on the enemy.",
    "Duration": "3 sec.",
  },
  ]