export const BELETH_RING_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const BELETH_RING_V1 = [
  {
    "Max MP": "+22",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "HP Recovery Rate": "+1",
    "MP Recovery Rate": "+1",
    "Soulshot damage": "+2%",
    "Spiritshot damage": "+2%",
    "All received Critical Damage": "-",
    "Skill Cooldown": "-",
    "Sleep Atk. Rate": "+15%",
    "Hold Atk. Rate": "+15%",
    "Paralysis Atk. Rate": "+10%",
    "Sleep Resistance": "+15%",
    "Hold Resistance": "+15%",
    "Paralysis Resistance": "+10%",
  },
  {
    "Max MP": "+32",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "HP Recovery Rate": "+1",
    "MP Recovery Rate": "+1",
    "Soulshot damage": "+3%",
    "Spiritshot damage": "+3%",
    "All received Critical Damage": "-",
    "Skill Cooldown": "-",
    "Sleep Atk. Rate": "+20%",
    "Hold Atk. Rate": "+20%",
    "Paralysis Atk. Rate": "+15%",
    "Sleep Resistance": "+20%",
    "Hold Resistance": "+20%",
    "Paralysis Resistance": "+15%",
  },
  {
    "Max MP": "+40",
    "P. Atk.": "+20",
    "M. Atk.": "+20",
    "HP Recovery Rate": "+1",
    "MP Recovery Rate": "+1",
    "Soulshot damage": "+6%",
    "Spiritshot damage": "+6%",
    "All received Critical Damage": "-10%",
    "Skill Cooldown": "-",
    "Sleep Atk. Rate": "+25%",
    "Hold Atk. Rate": "+25%",
    "Paralysis Atk. Rate": "+20%",
    "Sleep Resistance": "+25%",
    "Hold Resistance": "+25%",
    "Paralysis Resistance": "+20%",
  },
  {
    "Max MP": "+60",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "HP Recovery Rate": "+2",
    "MP Recovery Rate": "+2",
    "Soulshot damage": "+12%",
    "Spiritshot damage": "+12%",
    "All received Critical Damage": "-13%",
    "Skill Cooldown": "-3%",
    "Sleep Atk. Rate": "+25%",
    "Hold Atk. Rate": "+25%",
    "Paralysis Atk. Rate": "+20%",
    "Sleep Resistance": "+25%",
    "Hold Resistance": "+25%",
    "Paralysis Resistance": "+20%",
  },
  {
    "Max MP": "+85",
    "P. Atk.": "+100",
    "M. Atk.": "+100",
    "HP Recovery Rate": "+2",
    "MP Recovery Rate": "+2",
    "Soulshot damage": "+15%",
    "Spiritshot damage": "+15%",
    "All received Critical Damage": "-16%",
    "Skill Cooldown": "-5%",
    "Sleep Atk. Rate": "+25%",
    "Hold Atk. Rate": "+25%",
    "Paralysis Atk. Rate": "+20%",
    "Sleep Resistance": "+25%",
    "Hold Resistance": "+25%",
    "Paralysis Resistance": "+20%",
  },
  {
    "Max MP": "+100",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "HP Recovery Rate": "+3",
    "MP Recovery Rate": "+3",
    "Soulshot damage": "+20%",
    "Spiritshot damage": "+20%",
    "All received Critical Damage": "-20%",
    "Skill Cooldown": "-10%",
    "Sleep Atk. Rate": "+25%",
    "Hold Atk. Rate": "+25%",
    "Paralysis Atk. Rate": "+20%",
    "Sleep Resistance": "+25%",
    "Hold Resistance": "+25%",
    "Paralysis Resistance": "+20%",
  },
  {
    "Max MP": "+120",
    "P. Atk.": "+300",
    "M. Atk.": "+300",
    "HP Recovery Rate": "+5",
    "MP Recovery Rate": "+5",
    "Soulshot damage": "+22%",
    "Spiritshot damage": "+22%",
    "All received Critical Damage": "-23%",
    "Skill Cooldown": "-10%",
    "Sleep Atk. Rate": "+30%",
    "Hold Atk. Rate": "+30%",
    "Paralysis Atk. Rate": "+30%",
    "Sleep Resistance": "+30%",
    "Hold Resistance": "+30%",
    "Paralysis Resistance": "+30%",
  },
]

export const BELETH_RING_TRIGGER_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Received Skill Damage -10%.",
    "Duration": "5 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Received Skill Damage -11%.",
    "Duration": "5 sec.",
  },
  ]
