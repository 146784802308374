export const ORFENS_EARRING_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const ORFENS_EARRING_V1 = [
  {
    "Max MP": "+19",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+2%",
    "Skill MP Consumption": "-2%",
    "MP Vampiric": "-",
    "MP Recovery Rate": "-",
    "Received damage": "-",
    "Hold Atk. Rate": "+10%",
    "Silence Atk. Rate": "+5%",
    "Fear Atk. Rate": "+5%",
    "Hold Resistance": "+10%",
    "Silence Resistance": "+5%",
    "Fear Resistance": "+5%",
  },
  {
    "Max MP": "+25",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+4%",
    "Skill MP Consumption": "-3%",
    "MP Vampiric": "-",
    "MP Recovery Rate": "+1",
    "Received damage": "-",
    "Hold Atk. Rate": "+15%",
    "Silence Atk. Rate": "+10%",
    "Fear Atk. Rate": "+10%",
    "Hold Resistance": "+15%",
    "Silence Resistance": "+10%",
    "Fear Resistance": "+10%",
  },
  {
    "Max MP": "+31",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received Healing": "+6%",
    "Skill MP Consumption": "-5%",
    "MP Vampiric": "Absorbs 3% of the inflicted damage as MP.",
    "MP Recovery Rate": "+1",
    "Received damage": "-",
    "Hold Atk. Rate": "+20%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Hold Resistance": "+20%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+40",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Received Healing": "+8%",
    "Skill MP Consumption": "-6%",
    "MP Vampiric": "Absorbs 3% of the inflicted damage as MP.",
    "MP Recovery Rate": "+2",
    "Received damage": "-",
    "Hold Atk. Rate": "+20%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Hold Resistance": "+20%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+50",
    "P. Atk.": "+100",
    "M. Atk.": "+100",
    "P. Def.": "+200",
    "M. Def.": "+200",
    "Received Healing": "+10%",
    "Skill MP Consumption": "-7%",
    "MP Vampiric": "Absorbs 3% of the inflicted damage as MP.",
    "MP Recovery Rate": "+3",
    "Received damage": "-1%",
    "Hold Atk. Rate": "+20%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Hold Resistance": "+20%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+60",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "P. Def.": "+300",
    "M. Def.": "+300",
    "Received Healing": "+15%",
    "Skill MP Consumption": "-10%",
    "MP Vampiric": "Absorbs 3% of the inflicted damage as MP.",
    "MP Recovery Rate": "+5",
    "Received damage": "-2%",
    "Hold Atk. Rate": "+20%",
    "Silence Atk. Rate": "+15%",
    "Fear Atk. Rate": "+15%",
    "Hold Resistance": "+20%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
  },
  {
    "Max MP": "+80",
    "P. Atk.": "+300",
    "M. Atk.": "+500",
    "P. Def.": "+400",
    "M. Def.": "+400",
    "Received Healing": "+15%",
    "Skill MP Consumption": "-12%",
    "MP Vampiric": "Absorbs 3% of the inflicted damage as MP.",
    "MP Recovery Rate": "+10",
    "Received damage": "-5%",
    "Hold Atk. Rate": "+20%",
    "Silence Atk. Rate": "+20%",
    "Fear Atk. Rate": "+20%",
    "Hold Resistance": "+20%",
    "Silence Resistance": "+20%",
    "Fear Resistance": "+20%",
  },
]

export const ORFENS_EARRING_TRIGGER_V1 = [
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Cooldown": "-",
},
{
  "Probability": "Basic Attack: 50% / Skill: 50%",
  "Effect": "Recovers 10% of MP.",
  "Cooldown": "5 min.",
},
{
  "Probability": "Basic Attack: 50% / Skill: 50%",
  "Effect": "Recovers 10% of MP.",
  "Cooldown": "5 min.",
},
]

export const ORFENS_EARRING_TRIGGER2_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "Basic Attack: 1% / Skill: 1%",
    "Effect": "Inflicts Hold on the enemy.",
    "Duration": "5 sec.",
  },
  {
    "Probability": "Basic Attack: 1% / Skill: 1%",
    "Effect": "Inflicts Hold on the enemy.",
    "Duration": "5 sec.",
  },
  ]