export const BLESSED_TALISMAN_OF_SPEED_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "60%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "45%",
  },
  {
    "Value": "+4",
    "Probability": "40%",
  },
  {
    "Value": "+5",
    "Probability": "35%",
  },
  {
    "Value": "+6",
    "Probability": "30%",
  },
  {
    "Value": "+7",
    "Probability": "25%",
  },
  {
    "Value": "+8",
    "Probability": "20%",
  },
  {
    "Value": "+9",
    "Probability": "10%",
  },
  {
    "Value": "+10",
    "Probability": "5%",
  },
]

export const BLESSED_TALISMAN_OF_SPEED_V1 = [
  {
    "P. Def.": "+5",
    "M. Def.": "+5",
    "Max HP": "-",
    "Speed": "-",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+10",
    "M. Def.": "+10",
    "Max HP": "+50",
    "Speed": "+1",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+20",
    "M. Def.": "+20",
    "Max HP": "+100",
    "Speed": "+1",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+30",
    "M. Def.": "+30",
    "Max HP": "+200",
    "Speed": "+1",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+45",
    "M. Def.": "+45",
    "Max HP": "+400",
    "Speed": "+1",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+55",
    "M. Def.": "+55",
    "Max HP": "+800",
    "Speed": "+1",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+70",
    "M. Def.": "+70",
    "Max HP": "+1250",
    "Speed": "+2",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+190",
    "M. Def.": "+190",
    "Max HP": "+1750",
    "Speed": "+3",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+225",
    "M. Def.": "+225",
    "Max HP": "+2500",
    "Speed": "+5",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+275",
    "M. Def.": "+275",
    "Max HP": "+4000",
    "Speed": "+7",
    "Weapon Damage": "-",
  },
  {
    "P. Def.": "+400",
    "M. Def.": "+400",
    "Max HP": "+6000",
    "Speed": "+10",
    "Weapon Damage": "+5%",
  }
]

export const BLESSED_TALISMAN_OF_SPEED_TRIGGER_V1 = [
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+5",
    "Casting Spd.": "+5",
    "P. Skill Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+10",
    "Casting Spd.": "+10",
    "P. Skill Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+30",
    "Casting Spd.": "+30",
    "P. Skill Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+50",
    "Casting Spd.": "+50",
    "P. Skill Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+115",
    "Casting Spd.": "+115",
    "P. Skill Critical Damage": "+45",
    "M. Skill Critical Damage": "+45",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+160",
    "Casting Spd.": "+160",
    "P. Skill Critical Damage": "+80",
    "M. Skill Critical Damage": "+80",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+185",
    "Casting Spd.": "+185",
    "P. Skill Critical Damage": "+200",
    "M. Skill Critical Damage": "+200",
    "Duration": "5 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+215",
    "Casting Spd.": "+215",
    "P. Skill Critical Damage": "+450",
    "M. Skill Critical Damage": "+450",
    "Duration": "10 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+245",
    "Casting Spd.": "+245",
    "P. Skill Critical Damage": "+850",
    "M. Skill Critical Damage": "+850",
    "Duration": "10 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+280",
    "Casting Spd.": "+280",
    "P. Skill Critical Damage": "+1300",
    "M. Skill Critical Damage": "+1300",
    "Duration": "10 sec.",
    "Cooldown": "10 sec.",
  },
  {
    "Probability": "Basic Attack: 5% / Skill: 10%",
    "Atk. Spd.": "+300",
    "Casting Spd.": "+300",
    "P. Skill Critical Damage": "+1500",
    "M. Skill Critical Damage": "+1500",
    "Duration": "15 sec.",
    "Cooldown": "10 sec.",
  }
]