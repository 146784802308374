export const FRINTEZZA_DOLL_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "7%",
  },
  {
    "Value": "Lv. 3",
    "Probability": "70%",
  },
  {
    "Value": "Lv. 4",
    "Probability": "50%",
  },
  {
    "Value": "Lv. 5",
    "Probability": "30%",
  },
  {
    "Value": "Lv. 6",
    "Probability": "30%",
  },
]

export const FRINTEZZA_DOLL_V1 = [
  {
    "STR": "+1",
    "INT": "+1",
    "DEX": "-",
    "WIT": "-",
    "CON": "-",
    "MEN": "-",
    "Skill Power": "+1%",
    "Atk. Spd.": "+20",
    "Casting Spd.": "+20",
    "Speed": "+1",
    "Acquired XP/ SP": "-",
  },
  {
    "STR": "+1",
    "INT": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "CON": "+1",
    "MEN": "+1",
    "Skill Power": "+2%",
    "Atk. Spd.": "+50",
    "Casting Spd.": "+50",
    "Speed": "+2",
    "Acquired XP/ SP": "-",
  },
  {
    "STR": "+2",
    "INT": "+2",
    "DEX": "+2",
    "WIT": "+2",
    "CON": "+2",
    "MEN": "+2",
    "Skill Power": "+5%",
    "Atk. Spd.": "+100",
    "Casting Spd.": "+100",
    "Speed": "+5",
    "Acquired XP/ SP": "-",
  },
  {
    "STR": "+2",
    "INT": "+2",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+2",
    "MEN": "+2",
    "Skill Power": "+6%",
    "Atk. Spd.": "+110",
    "Casting Spd.": "+110",
    "Speed": "+5",
    "Acquired XP/ SP": "-",
  },
  {
    "STR": "+3",
    "INT": "+3",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+3",
    "MEN": "+3",
    "Skill Power": "+8%",
    "Atk. Spd.": "+120",
    "Casting Spd.": "+120",
    "Speed": "+5",
    "Acquired XP/ SP": "-",
  },
  {
    "STR": "+4",
    "INT": "+4",
    "DEX": "+4",
    "WIT": "+4",
    "CON": "+4",
    "MEN": "+4",
    "Skill Power": "+10%",
    "Atk. Spd.": "+130",
    "Casting Spd.": "+130",
    "Speed": "+6",
    "Acquired XP/ SP": "+5%",
  },
]