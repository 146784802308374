export const QUEEN_ANTS_RING_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const QUEEN_ANTS_RING_V1 = [
  {
    "Max MP": "+9",
    "P. Accuracy": "+1",
    "M. Accuracy": "+1",
    "P. Atk.": "+4%",
    "M. Atk.": "+4%",
    "P. Def.": "-",
    "M. Def.": "-",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "-",
    "Infection Atk. Rate": "+10%",
    "Infection Resistance": "+10%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+15",
    "P. Accuracy": "+2",
    "M. Accuracy": "+2",
    "P. Atk.": "+6%",
    "M. Atk.": "+6%",
    "P. Def.": "-",
    "M. Def.": "-",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "-",
    "Infection Atk. Rate": "+15%",
    "Infection Resistance": "+15%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+21",
    "P. Accuracy": "+3",
    "M. Accuracy": "+3",
    "P. Atk.": "+7%",
    "M. Atk.": "+7%",
    "P. Def.": "+35",
    "M. Def.": "+35",
    "P. Critical Damage": "+10%",
    "M. Skill Critical Damage": "+5%",
    "All Critical Damage": "-",
    "Infection Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+30",
    "P. Accuracy": "+4",
    "M. Accuracy": "+4",
    "P. Atk.": "+9%",
    "M. Atk.": "+9%",
    "P. Def.": "+65",
    "M. Def.": "+65",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "+10%",
    "Infection Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+40",
    "P. Accuracy": "+6",
    "M. Accuracy": "+6",
    "P. Atk.": "+11%",
    "M. Atk.": "+11%",
    "P. Def.": "+95",
    "M. Def.": "+95",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "+15%",
    "Infection Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+50",
    "P. Accuracy": "+10",
    "M. Accuracy": "+10",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "P. Def.": "+125",
    "M. Def.": "+125",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "+20%",
    "Infection Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Imprisonment Atk. Rate": "-",
    "Imprisonment Resistance" : "-",
  },
  {
    "Max MP": "+70",
    "P. Accuracy": "+15",
    "M. Accuracy": "+15",
    "P. Atk.": "+20%",
    "M. Atk.": "+20%",
    "P. Def.": "+200",
    "M. Def.": "+200",
    "P. Critical Damage": "-",
    "M. Skill Critical Damage": "-",
    "All Critical Damage": "+23%",
    "Infection Atk. Rate": "+20%",
    "Infection Resistance": "+20%",
    "Imprisonment Atk. Rate": "+5%",
    "Imprisonment Resistance" : "+5%",
  },
]

export const QUEEN_ANTS_RING_TRIGGER_V1 = [
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "When hit: 1%",
  "Effect": "Grants immunity to debuffs that decrease P./M. Atk.",
  "Duration": "5 sec.",
},
{
  "Probability": "When hit: 1%",
  "Effect": "Grants immunity to debuffs that decrease P./M. Atk. Damage +2%.",
  "Duration": "5 sec.",
},
]