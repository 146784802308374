export const AZTAKANS_ORACLE_WAND_ENCHANT_V1 = [ 
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "100%",
  },
  {
    "Value": "+3",
    "Probability": "100%",
  },
  {
    "Value": "+4",
    "Probability": "100%",
  },
  {
    "Value": "+5",
    "Probability": "33%",
  },
  {
    "Value": "+6",
    "Probability": "15%",
  },
  {
    "Value": "+7",
    "Probability": "15%",
  },
  {
    "Value": "+8",
    "Probability": "8%",
  },
  {
    "Value": "+9",
    "Probability": "4%",
  },
  {
    "Value": "+10",
    "Probability": "2%",
  },
]

export const AZTAKANS_ORACLE_WAND_V1 = [ 
  {
    "P. Atk.": "222",
    "M. Atk.": "204",
    "Atk. Spd.": "+5",
    "Casting Spd.": "+5",
    "INT": "+2",
    "CON": "+1",
    "Skill Power": "-",
    "Healing Power": "-",
  },
  {
    "P. Atk.": "237",
    "M. Atk.": "214",
    "Atk. Spd.": "+10",
    "Casting Spd.": "+10",
    "INT": "+2",
    "CON": "+1",
    "Skill Power": "-",
    "Healing Power": "-",
  },
  {
    "P. Atk.": "252",
    "M. Atk.": "224",
    "Atk. Spd.": "+15",
    "Casting Spd.": "+15",
    "INT": "+2",
    "CON": "+1",
    "Skill Power": "-",
    "Healing Power": "-",
  },
  {
    "P. Atk.": "267",
    "M. Atk.": "234",
    "Atk. Spd.": "+20",
    "Casting Spd.": "+20",
    "INT": "+2",
    "CON": "+1",
    "Skill Power": "-",
    "Healing Power": "-",
  },
  {
    "P. Atk.": "302",
    "M. Atk.": "264",
    "Atk. Spd.": "+30",
    "Casting Spd.": "+30",
    "INT": "+2",
    "CON": "+1",
    "Skill Power": "-",
    "Healing Power": "-",
  },
  {
    "P. Atk.": "337",
    "M. Atk.": "294",
    "Atk. Spd.": "+40",
    "Casting Spd.": "+40",
    "INT": "+3",
    "CON": "+2",
    "Skill Power": "+1.5%",
    "Healing Power": "+300",
  },
  {
    "P. Atk.": "372",
    "M. Atk.": "324",
    "Atk. Spd.": "+50",
    "Casting Spd.": "+50",
    "INT": "+4",
    "CON": "+3",
    "Skill Power": "+1.8%",
    "Healing Power": "+400",
  },
  {
    "P. Atk.": "447",
    "M. Atk.": "374",
    "Atk. Spd.": "+60",
    "Casting Spd.": "+60",
    "INT": "+5",
    "CON": "+4",
    "Skill Power": "+2.1%",
    "Healing Power": "+500",
  },
  {
    "P. Atk.": "522",
    "M. Atk.": "424",
    "Atk. Spd.": "+70",
    "Casting Spd.": "+70",
    "INT": "+6",
    "CON": "+5",
    "Skill Power": "+2.5%",
    "Healing Power": "+600",
  },
  {
    "P. Atk.": "596",
    "M. Atk.": "474",
    "Atk. Spd.": "+80",
    "Casting Spd.": "+80",
    "INT": "+7",
    "CON": "+6",
    "Skill Power": "+3%",
    "Healing Power": "+800",
  },
  {
    "P. Atk.": "672",
    "M. Atk.": "524",
    "Atk. Spd.": "+100",
    "Casting Spd.": "+100",
    "INT": "+8",
    "CON": "+7",
    "Skill Power": "+5%",
    "Healing Power": "+1000",
  },
]
