export const TALISMAN_OF_BAIUM_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "90%",
  },
  {
    "Value": "+2",
    "Probability": "80%",
  },
  {
    "Value": "+3",
    "Probability": "70%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "5%",
  },
  {
    "Value": "+6",
    "Probability": "20%",
  },
  {
    "Value": "+7",
    "Probability": "?",
  },
  {
    "Value": "+8",
    "Probability": "?",
  },
  {
    "Value": "+9",
    "Probability": "?",
  },
  {
    "Value": "+10",
    "Probability": "?",
  },
]

export const TALISMAN_OF_BAIUM_V1 = [
  {
    "P. Atk.": "+284",
    "M. Atk.": "+392",
    "Skill Power": "+5%",
    "P. Critical Rate": "+20",
    "M. Skill Critical Rate": "+20",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+5%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+1",
    "INT": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "CON": "+1",
    "MEN": "+1",
  },
  {
    "P. Atk.": "+300",
    "M. Atk.": "+415",
    "Skill Power": "+5%",
    "P. Critical Rate": "+20",
    "M. Skill Critical Rate": "+20",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+5%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+1",
    "INT": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "P. Atk.": "+334",
    "M. Atk.": "+449",
    "Skill Power": "+5%",
    "P. Critical Rate": "+20",
    "M. Skill Critical Rate": "+20",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+5%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+1",
    "INT": "+1",
    "DEX": "+2",
    "WIT": "+2",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "P. Atk.": "+376",
    "M. Atk.": "+491",
    "Skill Power": "+5%",
    "P. Critical Rate": "+30",
    "M. Skill Critical Rate": "+30",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+5%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+2",
    "INT": "+2",
    "DEX": "+2",
    "WIT": "+2",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "P. Atk.": "+426",
    "M. Atk.": "+541",
    "Skill Power": "+5%",
    "P. Critical Rate": "+40",
    "M. Skill Critical Rate": "+40",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+6%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+2",
    "INT": "+2",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "P. Atk.": "+485",
    "M. Atk.": "+600",
    "Skill Power": "+5%",
    "P. Critical Rate": "+50",
    "M. Skill Critical Rate": "+50",
    "All Critical Rate": "-",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+3",
    "INT": "+3",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "P. Atk.": "+650",
    "M. Atk.": "+650",
    "Skill Power": "+5%",
    "P. Critical Rate": "-",
    "M. Skill Critical Rate": "-",
    "All Critical Rate": "+60",
    "P. Def.": "+200",
    "M. Def.": "+200",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+3",
    "INT": "+3",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+3",
    "MEN": "+3",
  },
  {
    "P. Atk.": "+800",
    "M. Atk.": "+800",
    "Skill Power": "+5%",
    "P. Critical Rate": "-",
    "M. Skill Critical Rate": "-",
    "All Critical Rate": "+70",
    "P. Def.": "+400",
    "M. Def.": "+400",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+3",
    "INT": "+4",
    "DEX": "+4",
    "WIT": "+3",
    "CON": "+4",
    "MEN": "+3",
  },
  {
    "P. Atk.": "+1000",
    "M. Atk.": "+1000",
    "Skill Power": "+5%",
    "P. Critical Rate": "-",
    "M. Skill Critical Rate": "-",
    "All Critical Rate": "+80",
    "P. Def.": "+600",
    "M. Def.": "+600",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+4",
    "INT": "+4",
    "DEX": "+4",
    "WIT": "+4",
    "CON": "+4",
    "MEN": "+4",
  },
  {
    "P. Atk.": "+1500",
    "M. Atk.": "+1500",
    "Skill Power": "+5%",
    "P. Critical Rate": "-",
    "M. Skill Critical Rate": "-",
    "All Critical Rate": "+90",
    "P. Def.": "+800",
    "M. Def.": "+800",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+4",
    "INT": "+5",
    "DEX": "+5",
    "WIT": "+4",
    "CON": "+5",
    "MEN": "+4",
  },
  {
    "P. Atk.": "+2000",
    "M. Atk.": "+2000",
    "Skill Power": "+5%",
    "P. Critical Rate": "-",
    "M. Skill Critical Rate": "-",
    "All Critical Rate": "+100",
    "P. Def.": "+1000",
    "M. Def.": "+1000",
    "Resistance to All Weapons": "+7%",
    "Skill MP Consumption": "-5%",
    "Skill Cooldown": "-3%",
    "STR": "+5",
    "INT": "+5",
    "DEX": "+5",
    "WIT": "+5",
    "CON": "+5",
    "MEN": "+5",
  },
]

export const TALISMAN_OF_BAIUM_2_V1 = [
  {
    "Skill Cooldown": "-",
    "Skill Power": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
  },
  {
    "Skill Cooldown": "-1%",
    "Skill Power": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
  },
  {
    "Skill Cooldown": "-2%",
    "Skill Power": "+1%",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
  },
  {
    "Skill Cooldown": "-3%",
    "Skill Power": "+1%",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
  },
  {
    "Skill Cooldown": "-4%",
    "Skill Power": "+2%",
    "P. Def.": "+20",
    "M. Def.": "+20",
    "All Critical Damage": "-",
  },
  {
    "Skill Cooldown": "-7%",
    "Skill Power": "+3%",
    "P. Def.": "+50",
    "M. Def.": "+50",
    "All Critical Damage": "+50",
  },
  {
    "Skill Cooldown": "-8%",
    "Skill Power": "+4%",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "All Critical Damage": "+50",
  },
  {
    "Skill Cooldown": "-9%",
    "Skill Power": "+5%",
    "P. Def.": "+200",
    "M. Def.": "+200",
    "All Critical Damage": "+60",
  },
  {
    "Skill Cooldown": "-10%",
    "Skill Power": "+6%",
    "P. Def.": "+300",
    "M. Def.": "+300",
    "All Critical Damage": "+70",
  },
  {
    "Skill Cooldown": "-11%",
    "Skill Power": "+7%",
    "P. Def.": "+400",
    "M. Def.": "+400",
    "All Critical Damage": "+80",
  },
  {
    "Skill Cooldown": "-12%",
    "Skill Power": "+8%",
    "P. Def.": "+500",
    "M. Def.": "+500",
    "All Critical Damage": "+100",
  },
]