export const ADENS_SOUL_CRYSTAL_WEAPON_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
    "Failure": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "70%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 3",
    "Probability": "60%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 4",
    "Probability": "50%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 5",
    "Probability": "40%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 6",
    "Probability": "60%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 7",
    "Probability": "55%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 8",
    "Probability": "50%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 9",
    "Probability": "45%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 10",
    "Probability": "40%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 11",
    "Probability": "50%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 12",
    "Probability": "45%",
    "Failure": "Lv. 11",
  },
  {
    "Value": "Lv. 13",
    "Probability": "40%",
    "Failure": "Lv. 11",
  },
  {
    "Value": "Lv. 14",
    "Probability": "35%",
    "Failure": "Lv. 11",
  },
  {
    "Value": "Lv. 15",
    "Probability": "30%",
    "Failure": "Lv. 11",
  },
  {
    "Value": "Lv. 16",
    "Probability": "30%",
    "Failure": "Lv. 11",
  },
  {
    "Value": "Lv. 17",
    "Probability": "25%",
    "Failure": "Lv. 16",
  },
  {
    "Value": "Lv. 18",
    "Probability": "20%",
    "Failure": "Lv. 16",
  },
  {
    "Value": "Lv. 19",
    "Probability": "15%",
    "Failure": "Lv. 16",
  },
  {
    "Value": "Lv. 20",
    "Probability": "10%",
    "Failure": "Lv. 16",
  },
]

export const ADENS_SOUL_CRYSTAL_WEAPON_V1 = [
  {
    "Max HP": "+4%",
    "Max MP": "+4%",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "Atk. Spd.": "+3%",
    "Casting Spd.": "+3%",
    "Speed": "+4",
    "P. Critical Rate": "+30",
    "P. Skill Critical Rate": "+1%",
    "M. Skill Critical Rate": "+3%",
    "P. Critical Damage": "+95",
    "P. Skill Critical Damage": "+0.5%",
    "M. Skill Critical Damage": "+0.5%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 3%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 3%)",
  },
  {
    "Max HP": "+5%",
    "Max MP": "+5%",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "Atk. Spd.": "+4%",
    "Casting Spd.": "+4%",
    "Speed": "+4.5",
    "P. Critical Rate": "+35",
    "P. Skill Critical Rate": "+1.5%",
    "M. Skill Critical Rate": "+4%",
    "P. Critical Damage": "+115",
    "P. Skill Critical Damage": "+0.7%",
    "M. Skill Critical Damage": "+0.7%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 4%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 4%)",
  },
  {
    "Max HP": "+6%",
    "Max MP": "+6%",
    "P. Atk.": "+90",
    "M. Atk.": "+90",
    "Atk. Spd.": "+5%",
    "Casting Spd.": "+5%",
    "Speed": "+5",
    "P. Critical Rate": "+40",
    "P. Skill Critical Rate": "+2%",
    "M. Skill Critical Rate": "+5%",
    "P. Critical Damage": "+135",
    "P. Skill Critical Damage": "+0.9%",
    "M. Skill Critical Damage": "+0.9%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 5%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 5%)",
  },
  {
    "Max HP": "+7%",
    "Max MP": "+7%",
    "P. Atk.": "+110",
    "M. Atk.": "+110",
    "Atk. Spd.": "+6%",
    "Casting Spd.": "+6%",
    "Speed": "+5.5",
    "P. Critical Rate": "+45",
    "P. Skill Critical Rate": "+2.5%",
    "M. Skill Critical Rate": "+6%",
    "P. Critical Damage": "+155",
    "P. Skill Critical Damage": "+1.1%",
    "M. Skill Critical Damage": "+1.1%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 6%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 6%)",
  },
  {
    "Max HP": "+8%",
    "Max MP": "+8%",
    "P. Atk.": "+130",
    "M. Atk.": "+130",
    "Atk. Spd.": "+8%",
    "Casting Spd.": "+8%",
    "Speed": "+6",
    "P. Critical Rate": "+50",
    "P. Skill Critical Rate": "+3%",
    "M. Skill Critical Rate": "+6.5%",
    "P. Critical Damage": "+175",
    "P. Skill Critical Damage": "+1.3%",
    "M. Skill Critical Damage": "+1.3%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 7%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 7%)",
  },
  {
    "Max HP": "+9%",
    "Max MP": "+9%",
    "P. Atk.": "+170",
    "M. Atk.": "+170",
    "Atk. Spd.": "+9%",
    "Casting Spd.": "+9%",
    "Speed": "+6.5",
    "P. Critical Rate": "+55",
    "P. Skill Critical Rate": "+3.5%",
    "M. Skill Critical Rate": "+8%",
    "P. Critical Damage": "+200",
    "P. Skill Critical Damage": "+1.5%",
    "M. Skill Critical Damage": "+1.5%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 9%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 9%)",
  },
  {
    "Max HP": "+10%",
    "Max MP": "+10%",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "Speed": "+7",
    "P. Critical Rate": "+60",
    "P. Skill Critical Rate": "+4%",
    "M. Skill Critical Rate": "+9%",
    "P. Critical Damage": "+220",
    "P. Skill Critical Damage": "+1.7%",
    "M. Skill Critical Damage": "+1.7%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 10%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 10%)",
  },
  {
    "Max HP": "+12%",
    "Max MP": "+12%",
    "P. Atk.": "+230",
    "M. Atk.": "+230",
    "Atk. Spd.": "+11%",
    "Casting Spd.": "+11%",
    "Speed": "+7.5",
    "P. Critical Rate": "+65",
    "P. Skill Critical Rate": "+4.5%",
    "M. Skill Critical Rate": "+10%",
    "P. Critical Damage": "+240",
    "P. Skill Critical Damage": "+1.9%",
    "M. Skill Critical Damage": "+1.9%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 11%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 11%)",
  },
  {
    "Max HP": "+14%",
    "Max MP": "+14%",
    "P. Atk.": "+260",
    "M. Atk.": "+260",
    "Atk. Spd.": "+12%",
    "Casting Spd.": "+12%",
    "Speed": "+8",
    "P. Critical Rate": "+70",
    "P. Skill Critical Rate": "+5%",
    "M. Skill Critical Rate": "+11%",
    "P. Critical Damage": "+260",
    "P. Skill Critical Damage": "+2.1%",
    "M. Skill Critical Damage": "+2.1%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 12%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 12%)",
  },
  {
    "Max HP": "+16%",
    "Max MP": "+16%",
    "P. Atk.": "+290",
    "M. Atk.": "+290",
    "Atk. Spd.": "+14%",
    "Casting Spd.": "+14%",
    "Speed": "+8.5",
    "P. Critical Rate": "+75",
    "P. Skill Critical Rate": "+5.5%",
    "M. Skill Critical Rate": "+12%",
    "P. Critical Damage": "+280",
    "P. Skill Critical Damage": "+2.3%",
    "M. Skill Critical Damage": "+2.3%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 13%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 13%)",
  },
  {
    "Max HP": "+18%",
    "Max MP": "+18%",
    "P. Atk.": "+340",
    "M. Atk.": "+340",
    "Atk. Spd.": "+15%",
    "Casting Spd.": "+15%",
    "Speed": "+9",
    "P. Critical Rate": "+80",
    "P. Skill Critical Rate": "+6%",
    "M. Skill Critical Rate": "+14%",
    "P. Critical Damage": "+300",
    "P. Skill Critical Damage": "+2.5%",
    "M. Skill Critical Damage": "+2.5%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 15%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 15%)",
  },
  {
    "Max HP": "+20%",
    "Max MP": "+20%",
    "P. Atk.": "+380",
    "M. Atk.": "+380",
    "Atk. Spd.": "+16%",
    "Casting Spd.": "+16%",
    "Speed": "+9.5",
    "P. Critical Rate": "+85",
    "P. Skill Critical Rate": "+6.5%",
    "M. Skill Critical Rate": "+15%",
    "P. Critical Damage": "+320",
    "P. Skill Critical Damage": "+2.7%",
    "M. Skill Critical Damage": "+2.7%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 16%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 16%)",
  },
  {
    "Max HP": "+22%",
    "Max MP": "+22%",
    "P. Atk.": "+420",
    "M. Atk.": "+420",
    "Atk. Spd.": "+17%",
    "Casting Spd.": "+17%",
    "Speed": "+10",
    "P. Critical Rate": "+90",
    "P. Skill Critical Rate": "+7%",
    "M. Skill Critical Rate": "+16%",
    "P. Critical Damage": "+340",
    "P. Skill Critical Damage": "+2.9%",
    "M. Skill Critical Damage": "+2.9%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 17%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 17%)",
  },
  {
    "Max HP": "+25%",
    "Max MP": "+25%",
    "P. Atk.": "+460",
    "M. Atk.": "+460",
    "Atk. Spd.": "+18%",
    "Casting Spd.": "+18%",
    "Speed": "+10.5",
    "P. Critical Rate": "+95",
    "P. Skill Critical Rate": "+7.5%",
    "M. Skill Critical Rate": "+17%",
    "P. Critical Damage": "+360",
    "P. Skill Critical Damage": "+3.1%",
    "M. Skill Critical Damage": "+3.1%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 18%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 18%)",
  },
  {
    "Max HP": "+28%",
    "Max MP": "+28%",
    "P. Atk.": "+500",
    "M. Atk.": "+500",
    "Atk. Spd.": "+19%",
    "Casting Spd.": "+19%",
    "Speed": "+11",
    "P. Critical Rate": "+100",
    "P. Skill Critical Rate": "+8%",
    "M. Skill Critical Rate": "+18%",
    "P. Critical Damage": "+380",
    "P. Skill Critical Damage": "+3.3%",
    "M. Skill Critical Damage": "+3.3%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 19%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 19%)",
  },
  {
    "Max HP": "+29%",
    "Max MP": "+29%",
    "P. Atk.": "+600",
    "M. Atk.": "+600",
    "Atk. Spd.": "+20%",
    "Casting Spd.": "+20%",
    "Speed": "+11.2",
    "P. Critical Rate": "+110",
    "P. Skill Critical Rate": "+8.5%",
    "M. Skill Critical Rate": "+18.5%",
    "P. Critical Damage": "+400",
    "P. Skill Critical Damage": "+3.5%",
    "M. Skill Critical Damage": "+3.5%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 21%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 21%)",
  },
  {
    "Max HP": "+30%",
    "Max MP": "+30%",
    "P. Atk.": "+700",
    "M. Atk.": "+700",
    "Atk. Spd.": "+21%",
    "Casting Spd.": "+21%",
    "Speed": "+11.4",
    "P. Critical Rate": "+120",
    "P. Skill Critical Rate": "+9%",
    "M. Skill Critical Rate": "+19%",
    "P. Critical Damage": "+420",
    "P. Skill Critical Damage": "+3.7%",
    "M. Skill Critical Damage": "+3.7%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 22%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 22%)",
  },
  {
    "Max HP": "+31%",
    "Max MP": "+31%",
    "P. Atk.": "+800",
    "M. Atk.": "+800",
    "Atk. Spd.": "+22%",
    "Casting Spd.": "+22%",
    "Speed": "+11.6",
    "P. Critical Rate": "+130",
    "P. Skill Critical Rate": "+9.5%",
    "M. Skill Critical Rate": "+19.5%",
    "P. Critical Damage": "+440",
    "P. Skill Critical Damage": "+3.9%",
    "M. Skill Critical Damage": "+3.9%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 23%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 23%)",
  },
  {
    "Max HP": "+32%",
    "Max MP": "+32%",
    "P. Atk.": "+900",
    "M. Atk.": "+900",
    "Atk. Spd.": "+23%",
    "Casting Spd.": "+23%",
    "Speed": "+11.8",
    "P. Critical Rate": "+140",
    "P. Skill Critical Rate": "+10%",
    "M. Skill Critical Rate": "+20%",
    "P. Critical Damage": "+460",
    "P. Skill Critical Damage": "+4.1%",
    "M. Skill Critical Damage": "+4.1%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 24%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 24%)",
  },
  {
    "Max HP": "+33%",
    "Max MP": "+33%",
    "P. Atk.": "+1000",
    "M. Atk.": "+1000",
    "Atk. Spd.": "+24%",
    "Casting Spd.": "+24%",
    "Speed": "+12",
    "P. Critical Rate": "+150",
    "P. Skill Critical Rate": "+11%",
    "M. Skill Critical Rate": "+21%",
    "P. Critical Damage": "+500",
    "P. Skill Critical Damage": "+4.3%",
    "M. Skill Critical Damage": "+4.3%",
    "Shock Attack": "For 3 sec., stuns the target after an attack. (Probability: 25%)",
    "Silence Attack": "For 5 sec., inflicts Silence on the target after an attack. (Probability: 25%)",
  },
]