export const RING_OF_CORE_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const RING_OF_CORE_V1 = [
  {
    "Max MP": "+9",
    "P. Evasion": "+1",
    "M. Evasion": "+1",
    "Skill Evasion": "-",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+2%",
    "M. Def.": "+2%",
    "Received damage": "-2%",
    "Silence Atk. Rate": "+10%",
    "Silence Resistance": "+10%",
    "Fear Atk. Rate": "+10%",
    "Fear Resistance" : "+10%",
  },
  {
    "Max MP": "+15",
    "P. Evasion": "+2",
    "M. Evasion": "+2",
    "Skill Evasion": "-",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+4%",
    "M. Def.": "+4%",
    "Received damage": "-4%",
    "Silence Atk. Rate": "+15%",
    "Silence Resistance": "+15%",
    "Fear Atk. Rate": "+15%",
    "Fear Resistance" : "+15%",
  },
  {
    "Max MP": "+21",
    "P. Evasion": "+4",
    "M. Evasion": "+4",
    "Skill Evasion": "-",
    "P. Atk.": "+20",
    "M. Atk.": "+20",
    "P. Def.": "+6%",
    "M. Def.": "+6%",
    "Received damage": "-6%",
    "Silence Atk. Rate": "+20%",
    "Silence Resistance": "+20%",
    "Fear Atk. Rate": "+20%",
    "Fear Resistance" : "+20%",
  },
  {
    "Max MP": "+30",
    "P. Evasion": "+6",
    "M. Evasion": "+6",
    "Skill Evasion": "-",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "P. Def.": "+8%",
    "M. Def.": "+8%",
    "Received damage": "-8%",
    "Silence Atk. Rate": "+20%",
    "Silence Resistance": "+20%",
    "Fear Atk. Rate": "+20%",
    "Fear Resistance" : "+20%",
  },
  {
    "Max MP": "+40",
    "P. Evasion": "+8",
    "M. Evasion": "+8",
    "Skill Evasion": "-",
    "P. Atk.": "+100",
    "M. Atk.": "+100",
    "P. Def.": "+10%",
    "M. Def.": "+10%",
    "Received damage": "-10%",
    "Silence Atk. Rate": "+20%",
    "Silence Resistance": "+20%",
    "Fear Atk. Rate": "+20%",
    "Fear Resistance" : "+20%",
  },
  {
    "Max MP": "+50",
    "P. Evasion": "+10",
    "M. Evasion": "+10",
    "Skill Evasion": "-",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "P. Def.": "+15%",
    "M. Def.": "+15%",
    "Received damage": "-15%",
    "Silence Atk. Rate": "+20%",
    "Silence Resistance": "+20%",
    "Fear Atk. Rate": "+20%",
    "Fear Resistance" : "+20%",
  },
  {
    "Max MP": "+60",
    "P. Evasion": "+20",
    "M. Evasion": "+20",
    "Skill Evasion": "+20%",
    "P. Atk.": "+300",
    "M. Atk.": "+300",
    "P. Def.": "+20%",
    "M. Def.": "+20%",
    "Received damage": "-17%",
    "Silence Atk. Rate": "+20%",
    "Silence Resistance": "+20%",
    "Fear Atk. Rate": "+20%",
    "Fear Resistance" : "+20%",
  },
]

export const RING_OF_CORE_ACTIVE_V1 = [
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "Removes debuffs and anomaly states from the target.",
    "Cooldown": "2 min.",
  },
  {
    "Effect": "Removes debuffs and anomaly states from the target.",
    "Cooldown": "2 min.",
  },
  {
    "Effect": "Removes debuffs and anomaly states from the target.",
    "Cooldown": "2 min.",
  },
  {
    "Effect": "Removes debuffs and anomaly states from the target.",
    "Cooldown": "2 min.",
  },
  {
    "Effect": "Removes debuffs and anomaly states from the target.",
    "Cooldown": "2 min.",
  },
  ]

export const RING_OF_CORE_TRIGGER_V1 = [
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "-",
  "Effect": "-",
  "Duration": "-",
},
{
  "Probability": "When hit: 1%",
  "Effect": "Grants immunity to debuffs that decrease P./M. Def.",
  "Duration": "5 sec.",
},
{
  "Probability": "When hit: 1%",
  "Effect": "Grants immunity to debuffs that decrease P./M. Def. Damage +3%.",
  "Duration": "5 sec.",
},
]