export const New = ({children}:any) => {

  return(
      <section className="section__new">
          <div className="line"/>
          {children}
      </section>
  )
}

