export const SPINEL_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "65%",
  },
  {
    "Value": "Lv. 3",
    "Probability": "40%",
  },
  {
    "Value": "Lv. 4",
    "Probability": "35%",
  },
  {
    "Value": "Lv. 5",
    "Probability": "30%",
  },
  {
    "Value": "Lv. 6",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 7",
    "Probability": "20%",
  },
  {
    "Value": "Lv. 8",
    "Probability": "15%",
  },
]

export const SPINEL_V1 = [
  {
    "CON": "+1",
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "STR": "-",
    "INT": "-",
    "Received P. Critical Damage": "-",
    "Received M. Skill Critical Damage": "-",
    "All received Critical Damage": "-",
    "P. Def.": "+20",
    "M. Def.": "+10",
  },
  {
    "CON": "+1",
    "MEN": "+1",
    "DEX": "-",
    "WIT": "-",
    "STR": "-",
    "INT": "-",
    "Received P. Critical Damage": "-",
    "Received M. Skill Critical Damage": "-",
    "All received Critical Damage": "-",
    "P. Def.": "+30",
    "M. Def.": "+20",
  },
  {
    "CON": "+1",
    "MEN": "+1",
    "DEX": "+1",
    "WIT": "-",
    "STR": "-",
    "INT": "-",
    "Received P. Critical Damage": "-",
    "Received M. Skill Critical Damage": "-",
    "All received Critical Damage": "-",
    "P. Def.": "+60",
    "M. Def.": "+30",
  },
  {
    "CON": "+1",
    "MEN": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "STR": "-",
    "INT": "-",
    "Received P. Critical Damage": "-4%",
    "Received M. Skill Critical Damage": "-4%",
    "All received Critical Damage": "-",
    "P. Def.": "+100",
    "M. Def.": "+60",
  },
  {
    "CON": "+1",
    "MEN": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "STR": "+1",
    "INT": "+1",
    "Received P. Critical Damage": "-7%",
    "Received M. Skill Critical Damage": "-7%",
    "All received Critical Damage": "-",
    "P. Def.": "+150",
    "M. Def.": "+100",
  },
  {
    "CON": "+2",
    "MEN": "+2",
    "DEX": "+1",
    "WIT": "+1",
    "STR": "+1",
    "INT": "+1",
    "Received P. Critical Damage": "-10%",
    "Received M. Skill Critical Damage": "-10%",
    "All received Critical Damage": "-",
    "P. Def.": "+200",
    "M. Def.": "+150",
  },
  {
    "CON": "+2",
    "MEN": "+2",
    "DEX": "+2",
    "WIT": "+2",
    "STR": "+2",
    "INT": "+2",
    "Received P. Critical Damage": "-",
    "Received M. Skill Critical Damage": "-",
    "All received Critical Damage": "-15%",
    "P. Def.": "+250",
    "M. Def.": "+220",
  },
  {
    "CON": "+3",
    "MEN": "+3",
    "DEX": "+3",
    "WIT": "+3",
    "STR": "+3",
    "INT": "+3",
    "Received P. Critical Damage": "-",
    "Received M. Skill Critical Damage": "-",
    "All received Critical Damage": "-20%",
    "P. Def.": "+350",
    "M. Def.": "+300",
  },
]

export const SPINEL_TRIGGER_V1 = [
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 12 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 16 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 20 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 30 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 40 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 50 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 60 MP.",
    "Cooldown": "3 sec.",
  },
  {
    "Probability": "Skill: ?%",
    "Effect": "Recovers 70 MP.",
    "Cooldown": "3 sec.",
  },
]