export const BLESSED_TALISMAN_OF_AUTHORITY_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "60%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "35%",
  },
  {
    "Value": "+5",
    "Probability": "30%",
  },
  {
    "Value": "+6",
    "Probability": "28%",
  },
  {
    "Value": "+7",
    "Probability": "24%",
  },
  {
    "Value": "+8",
    "Probability": "26%",
  },
  {
    "Value": "+9",
    "Probability": "22%",
  },
  {
    "Value": "+10",
    "Probability": "20%",
  },
]

export const BLESSED_TALISMAN_OF_AUTHORITY_V1 = [
  {
    "P. Def.": "+10",
    "M. Def.": "+10",
    "Max HP": "+50",
    "Max MP": "+30",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "Skill Critical Damage": "-",
    "Skill Power": "-",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+13",
    "M. Def.": "+13",
    "Max HP": "+100",
    "Max MP": "+80",
    "P. Atk.": "+80",
    "M. Atk.": "+80",
    "Skill Critical Damage": "-",
    "Skill Power": "-",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+16",
    "M. Def.": "+16",
    "Max HP": "+150",
    "Max MP": "+130",
    "P. Atk.": "+110",
    "M. Atk.": "+110",
    "Skill Critical Damage": "-",
    "Skill Power": "-",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+19",
    "M. Def.": "+19",
    "Max HP": "+200",
    "Max MP": "+180",
    "P. Atk.": "+160",
    "M. Atk.": "+160",
    "Skill Critical Damage": "+50",
    "Skill Power": "-",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+25",
    "M. Def.": "+25",
    "Max HP": "+350",
    "Max MP": "+330",
    "P. Atk.": "+250",
    "M. Atk.": "+250",
    "Skill Critical Damage": "+150",
    "Skill Power": "+3%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+30",
    "M. Def.": "+30",
    "Max HP": "+450",
    "Max MP": "+430",
    "P. Atk.": "+330",
    "M. Atk.": "+330",
    "Skill Critical Damage": "+250",
    "Skill Power": "+5%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+36",
    "M. Def.": "+36",
    "Max HP": "+700",
    "Max MP": "+680",
    "P. Atk.": "+420",
    "M. Atk.": "+420",
    "Skill Critical Damage": "+350",
    "Skill Power": "+7%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+44",
    "M. Def.": "+44",
    "Max HP": "+1200",
    "Max MP": "+1080",
    "P. Atk.": "+520",
    "M. Atk.": "+520",
    "Skill Critical Damage": "+450",
    "Skill Power": "+9%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+52",
    "M. Def.": "+52",
    "Max HP": "+2000",
    "Max MP": "+1530",
    "P. Atk.": "+670",
    "M. Atk.": "+670",
    "Skill Critical Damage": "+600",
    "Skill Power": "+11%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+63",
    "M. Def.": "+63",
    "Max HP": "+3000",
    "Max MP": "+2280",
    "P. Atk.": "+850",
    "M. Atk.": "+850",
    "Skill Critical Damage": "+850",
    "Skill Power": "+13%",
    "PvP damage bonus": "-",
  },
  {
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Max HP": "+5000",
    "Max MP": "+3000",
    "P. Atk.": "+1200",
    "M. Atk.": "+1200",
    "Skill Critical Damage": "+1200",
    "Skill Power": "+15%",
    "PvP damage bonus": "+2%"
  }
]