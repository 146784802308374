export const BAIUM_DOLL_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "7%",
  },
  {
    "Value": "Lv. 3",
    "Probability": "70%",
  },
  {
    "Value": "Lv. 4",
    "Probability": "50%",
  },
  {
    "Value": "Lv. 5",
    "Probability": "30%",
  },
  {
    "Value": "Lv. 6",
    "Probability": "30%",
  },
]

export const BAIUM_DOLL_V1 = [
  {
    "Skill Cooldown": "-2%",
    "P. Def.": "+5%",
    "M. Def.": "+5%",
    "Silence Resistance": "+10%",
    "Fear Resistance": "+10%",
    "Paralysis Resistance": "+5%",
    "Knockback Resistance": "+5%",
    "Pull Resistance": "+5%",
    "Shock Resistance": "-",
    "Suppression Resistance": "-",
    "Imprisonment Resistance": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "Skill Cooldown": "-3%",
    "P. Def.": "+12%",
    "M. Def.": "+12%",
    "Silence Resistance": "+40%",
    "Fear Resistance": "+40%",
    "Paralysis Resistance": "+20%",
    "Knockback Resistance": "+20%",
    "Pull Resistance": "+20%",
    "Shock Resistance": "-",
    "Suppression Resistance": "-",
    "Imprisonment Resistance": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "Skill Cooldown": "-4%",
    "P. Def.": "+15%",
    "M. Def.": "+15%",
    "Silence Resistance": "+45%",
    "Fear Resistance": "+45%",
    "Paralysis Resistance": "+25%",
    "Knockback Resistance": "+25%",
    "Pull Resistance": "+25%",
    "Shock Resistance": "-",
    "Suppression Resistance": "-",
    "Imprisonment Resistance": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "Skill Cooldown": "-4%",
    "P. Def.": "+18%",
    "M. Def.": "+18%",
    "Silence Resistance": "+45%",
    "Fear Resistance": "+45%",
    "Paralysis Resistance": "+25%",
    "Knockback Resistance": "+25%",
    "Pull Resistance": "+27%",
    "Shock Resistance": "+10%",
    "Suppression Resistance": "-",
    "Imprisonment Resistance": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "Skill Cooldown": "-4%",
    "P. Def.": "+21%",
    "M. Def.": "+21%",
    "Silence Resistance": "+45%",
    "Fear Resistance": "+45%",
    "Paralysis Resistance": "+25%",
    "Knockback Resistance": "+25%",
    "Pull Resistance": "+30%",
    "Shock Resistance": "+15%",
    "Suppression Resistance": "-",
    "Imprisonment Resistance": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "Skill Cooldown": "-5%",
    "P. Def.": "+25%",
    "M. Def.": "+25%",
    "Silence Resistance": "+45%",
    "Fear Resistance": "+45%",
    "Paralysis Resistance": "+30%",
    "Knockback Resistance": "+30%",
    "Pull Resistance": "+30%",
    "Shock Resistance": "+20%",
    "Suppression Resistance": "+10%",
    "Imprisonment Resistance": "+10%",
    "Acquired XP/ SP": "+5%",
  },
]