export const BLESSED_CIRCLET_OF_HERO_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "60%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "35%",
  },
  {
    "Value": "+5",
    "Probability": "30%",
  },
  {
    "Value": "+6",
    "Probability": "28%",
  },
  {
    "Value": "+7",
    "Probability": "24%",
  },
  {
    "Value": "+8",
    "Probability": "26%",
  },
  {
    "Value": "+9",
    "Probability": "22%",
  },
  {
    "Value": "+10",
    "Probability": "20%",
  },
]

export const BLESSED_CIRCLET_OF_HERO_V1 = [
  {
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received damage": "-",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received damage": "-",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received damage": "-",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "-",
    "Max MP": "-",
    "Max CP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Received damage": "-",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+550",
    "Max MP": "+550",
    "Max CP": "+550",
    "P. Def.": "+6%",
    "M. Def.": "+6%",
    "Received damage": "-5%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+650",
    "Max MP": "+650",
    "Max CP": "+650",
    "P. Def.": "+7%",
    "M. Def.": "+7%",
    "Received damage": "-6%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+750",
    "Max MP": "+750",
    "Max CP": "+750",
    "P. Def.": "+8%",
    "M. Def.": "+8%",
    "Received damage": "-7%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+900",
    "Max MP": "+900",
    "Max CP": "+900",
    "P. Def.": "+9%",
    "M. Def.": "+9%",
    "Received damage": "-9%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+1500",
    "Max MP": "+1500",
    "Max CP": "+1500",
    "P. Def.": "+11%",
    "M. Def.": "+11%",
    "Received damage": "-11%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+2500",
    "Max MP": "+2500",
    "Max CP": "+2500",
    "P. Def.": "+14%",
    "M. Def.": "+14%",
    "Received damage": "-14%",
    "Received Skill Critical Damage": "-",
  },
  {
    "Max HP": "+5000",
    "Max MP": "+5000",
    "Max CP": "+5000",
    "P. Def.": "+20%",
    "M. Def.": "+20%",
    "Received damage": "-15%",
    "Received Skill Critical Damage": "-15%",
  }
]

export const BLESSED_CIRCLET_OF_HERO_TRIGGER_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "10%",
    "Effect": "Skill Critical Damage +10%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "10%",
    "Effect": "Skill Critical Damage +20%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "10%",
    "Effect": "Skill Critical Damage +30%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "15%",
    "Effect": "Skill Critical Damage +50%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "15%",
    "Effect": "Skill Critical Damage +70%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "15%",
    "Effect": "Skill Critical Damage +90%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
  {
    "Probability": "15%",
    "Effect": "Skill Critical Damage +100%",
    "Duration": "8 sec.",
    "Cooldown": "8 sec.",
  },
]