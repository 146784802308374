export const DRAGON_VALLEYS_EARRING_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "90%",
  },
  {
    "Value": "+3",
    "Probability": "80%",
  },
  {
    "Value": "+4",
    "Probability": "60%",
  },
  {
    "Value": "+5",
    "Probability": "50%",
  },
  {
    "Value": "+6",
    "Probability": "40%",
  },
  {
    "Value": "+7",
    "Probability": "30%",
  },
  {
    "Value": "+8",
    "Probability": "20%",
  },
  {
    "Value": "+9",
    "Probability": "10%",
  },
  {
    "Value": "+10",
    "Probability": "5%",
  },
]

export const DRAGON_VALLEYS_EARRING_V1 = [
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+1%",
    "Max MP": "-",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+2%",
    "Max MP": "+50",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+3%",
    "Max MP": "+100",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+4%",
    "Max MP": "+150",
    "Atk. Spd.": "+5",
    "Casting Spd.": "+5",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+5%",
    "Max MP": "+200",
    "Atk. Spd.": "+7",
    "Casting Spd.": "+7",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+10%",
    "Max MP": "+300",
    "Atk. Spd.": "+10",
    "Casting Spd.": "+10",
  },
  {
    "MEN": "-",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+12%",
    "Max MP": "+400",
    "Atk. Spd.": "+15",
    "Casting Spd.": "+15",
  },
  {
    "MEN": "+1",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+14%",
    "Max MP": "+500",
    "Atk. Spd.": "+30",
    "Casting Spd.": "+30",
  },
  {
    "MEN": "+1",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+18%",
    "Max MP": "+600",
    "Atk. Spd.": "+60",
    "Casting Spd.": "+60",
  },
  {
    "MEN": "+2",
    "DEX": "-",
    "WIT": "-",
    "Shock Resistance": "+22%",
    "Max MP": "+800",
    "Atk. Spd.": "+90",
    "Casting Spd.": "+90",
  },
  {
    "MEN": "+5",
    "DEX": "+1",
    "WIT": "+1",
    "Shock Resistance": "+30%",
    "Max MP": "+1000",
    "Atk. Spd.": "+150",
    "Casting Spd.": "+150",
  }
]