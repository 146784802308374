export const FRINTEZZAS_NECKLACE_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const FRINTEZZAS_NECKLACE_V1 = [
  {
    "Max MP": "+30",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+5%",
    "Skill Cooldown": "-5%",
    "All Critical Rate": "+2%",
    "All Critical Damage": "+20",
    "Damage Reflection": "-",
    "Sleep Atk. Rate": "+10%",
    "Sleep Resistance": "+10%",
  },
  {
    "Max MP": "+36",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+10%",
    "Skill Cooldown": "-6%",
    "All Critical Rate": "+3%",
    "All Critical Damage": "+50",
    "Damage Reflection": "-",
    "Sleep Atk. Rate": "+15%",
    "Sleep Resistance": "+15%",
  },
  {
    "Max MP": "+50",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+15%",
    "Skill Cooldown": "-8%",
    "All Critical Rate": "+5%",
    "All Critical Damage": "+100",
    "Damage Reflection": "Reflects 5% of received damage.",
    "Sleep Atk. Rate": "+20%",
    "Sleep Resistance": "+20%",
  },
  {
    "Max MP": "+65",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+18%",
    "Skill Cooldown": "-10%",
    "All Critical Rate": "+6%",
    "All Critical Damage": "+150",
    "Damage Reflection": "Reflects 6% of received damage.",
    "Sleep Atk. Rate": "+20%",
    "Sleep Resistance": "+20%",
  },
  {
    "Max MP": "+90",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+23%",
    "Skill Cooldown": "-12%",
    "All Critical Rate": "+7%",
    "All Critical Damage": "+200",
    "Damage Reflection": "Reflects 10% of received damage.",
    "Sleep Atk. Rate": "+20%",
    "Sleep Resistance": "+20%",
  },
  {
    "Max MP": "+120",
    "P. Atk.": "-",
    "M. Atk.": "-",
    "Skill Power": "+25%",
    "Skill Cooldown": "-15%",
    "All Critical Rate": "+10%",
    "All Critical Damage": "+300",
    "Damage Reflection": "Reflects 15% of received damage.",
    "Sleep Atk. Rate": "+20%",
    "Sleep Resistance": "+20%",
  },
  {
    "Max MP": "+150",
    "P. Atk.": "+300",
    "M. Atk.": "+300",
    "Skill Power": "+25%",
    "Skill Cooldown": "-15%",
    "All Critical Rate": "+12%",
    "All Critical Damage": "+400",
    "Damage Reflection": "Reflects 20% of received damage.",
    "Sleep Atk. Rate": "+20%",
    "Sleep Resistance": "+20%",
  },
]

export const FRINTEZZAS_NECKLACE_TRIGGER_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Inflicts Fear on the enemy.",
    "Duration": "3 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Inflicts Fear on the enemy.",
    "Duration": "3 sec.",
  },
  ]
