export const FERIONS_NECKLACE_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "30%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "8%",
  },
]

export const FERIONS_NECKLACE_V1 = [
  {
    "Max MP": "+30",
    "Max HP": "-",
    "P. Atk.": "+300",
    "M. Atk.": "+300",
    "P. Def.": "+500",
    "M. Def.": "+500",
    "Skill Power": "-",
    "Skill Cooldown": "-5%",
    "Imprisonment Atk. Rate": "-",
    "Shock Atk. Rate": "-",
    "Imprisonment Resistance": "-",
  },
  {
    "Max MP": "+36",
    "Max HP": "-",
    "P. Atk.": "+600",
    "M. Atk.": "+600",
    "P. Def.": "+1000",
    "M. Def.": "+1000",
    "Skill Power": "-",
    "Skill Cooldown": "-6%",
    "Imprisonment Atk. Rate": "-",
    "Shock Atk. Rate": "-",
    "Imprisonment Resistance": "-",
  },
  {
    "Max MP": "+50",
    "Max HP": "-",
    "P. Atk.": "+900",
    "M. Atk.": "+900",
    "P. Def.": "+1500",
    "M. Def.": "+1500",
    "Skill Power": "+5%",
    "Skill Cooldown": "-8%",
    "Imprisonment Atk. Rate": "-",
    "Shock Atk. Rate": "-",
    "Imprisonment Resistance": "-",
  },
  {
    "Max MP": "+65",
    "Max HP": "-",
    "P. Atk.": "+1200",
    "M. Atk.": "+1200",
    "P. Def.": "+2000",
    "M. Def.": "+2000",
    "Skill Power": "+10%",
    "Skill Cooldown": "-10%",
    "Imprisonment Atk. Rate": "+5%",
    "Shock Atk. Rate": "-",
    "Imprisonment Resistance": "+5%",
  },
  {
    "Max MP": "+90",
    "Max HP": "+1000",
    "P. Atk.": "+1500",
    "M. Atk.": "+1500",
    "P. Def.": "+3000",
    "M. Def.": "+3000",
    "Skill Power": "+15%",
    "Skill Cooldown": "-12%",
    "Imprisonment Atk. Rate": "+10%",
    "Shock Atk. Rate": "+10%",
    "Imprisonment Resistance": "+10%",
  },
  {
    "Max MP": "+120",
    "Max HP": "+3000",
    "P. Atk.": "+2000",
    "M. Atk.": "+2000",
    "P. Def.": "+4000",
    "M. Def.": "+4000",
    "Skill Power": "+20%",
    "Skill Cooldown": "-15%",
    "Imprisonment Atk. Rate": "+15%",
    "Shock Atk. Rate": "+15%",
    "Imprisonment Resistance": "+15%",
  },
  {
    "Max MP": "+150",
    "Max HP": "+5000",
    "P. Atk.": "+3000",
    "M. Atk.": "+3000",
    "P. Def.": "+5000",
    "M. Def.": "+5000",
    "Skill Power": "+25%",
    "Skill Cooldown": "-18%",
    "Imprisonment Atk. Rate": "+20%",
    "Shock Atk. Rate": "+20%",
    "Imprisonment Resistance": "+20%",
  },
]

export const FERIONS_NECKLACE_TRIGGER_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
    "Cooldown": "-",
  },
  {
    "Probability": "After an attack: 2%",
    "Effect": "Suppresses the target on attack: Atk. Spd. is 300, Casting Spd. is 300, Speed is 100.",
    "Duration": "3 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "After an attack: 5%",
    "Effect": "Suppresses the target on attack: Atk. Spd. is 300, Casting Spd. is 300, Speed is 100.",
    "Duration": "3 sec.",
    "Cooldown": "60 sec.",
  },
  ]
