const classGuides = [
    {
        "humans": [
            {
                "Gladiator • Duelist": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Samurai Long Sword*Samurai Long Sword",
                                "imgName": "samurai-long-sword-samurai-long-sword",
                                "type": "Dual Swords",
                                "type2": "Dual Swords / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Tallum Blade*Damascus Sword",
                                "imgName": "tallum-blade-damascus-sword",
                                "type": "Dual Swords",
                                "type2": "Dual Swords / Two-handed",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Warlord • Dreadnought": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Lance",
                                "imgName": "lance",
                                "type": "Spear",
                                "type2": "Spear / Two-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Tiphon's Spear",
                                "imgName": "tiphons-spear",
                                "type": "Spear",
                                "type2": "Spear / Two-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Paladin • Phoenix Knight": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "rightStart"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency. \n • MEN Pattern \n Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. (Not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Art of Battle Axe, Deadman's Glory \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Doom Shield",
                                "imgName": "doom-shield",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 230",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Barakiel's Axe \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Shield of Nightmare",
                                "imgName": "shield-of-nightmare",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 282",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Dark Avenger • Hell Knight": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "rightStart"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability. \n • MEN Pattern \n Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. (Not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Art of Battle Axe, Deadman's Glory \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Doom Shield",
                                "imgName": "doom-shield",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 230",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Barakiel's Axe \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Shield of Nightmare",
                                "imgName": "shield-of-nightmare",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 282",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Treasure Hunter • Adventurer": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Rogue Agility's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Demon's Dagger",
                                "imgName": "demons-dagger",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 170 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Naga's Storm",
                                "imgName": "nagas-storm",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 220 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Hawkeye • Sagittarius": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Dexterous Body's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Bow of Peril",
                                "imgName": "bow-of-peril",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 400 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Shyeed's Bow",
                                "imgName": "shyeeds-bow",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 570 \n M. Atk.: 151",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Circlet (Light)",
                                "imgName": "majestic-circlet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n MP Recovery Rate +5.5% \n P. Atk. +8% when using a bow \n P. Atk. +8% when using firearms",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Heavy)",
                                "imgName": "majestic-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +7 \n Atk. Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: Speed +4, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: Speed +7, Atk. Spd. +50.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Sorcerer • Archmage": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Necromancer • Soultaker": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Warlock • Arcana Lord": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Bishop • Cardinal": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "right"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "right"
                            },
                            {
                                "name": "MEN Pattern",
                                "imgName": "men-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Prophet • Hierophant": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Assists with obtaining Power of Intelligence's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Fatal Crush).",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Assists with obtaining Power of Intelligence's additional effects. \n Improves damage output. \n • STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Fatal Crush). \n • DEX Pattern \n Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (If playing as warrior after learning Fatal Crush, not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Assists with obtaining Power of Intelligence's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Fatal Crush).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Assists with obtaining Power of Intelligence's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Fatal Crush).",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Skill Critical Rate, Physical Skill Critical Damage or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Fatal Crush: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Fatal Crush: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Fatal Crush: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Skill Critical Rate, Physical Skill Critical Damage or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%. \n • If playing as warrior after learning Fatal Crush: \n Can be replaced for Leather Armor of Nightmare or Majestic Leather Armor.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush: \n Can be replaced for Helm of Nightmare (Heavy).",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Fatal Crush: \n Can be replaced for Gauntlets of Nightmare (Light) or Majestic Gauntlets (Heavy).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability). \n • If playing as warrior after learning Fatal Crush: \n Can be replaced for Boots of Nightmare (Light) or Majestic Boots (Heavy).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Human Death Messenger • Human Death Knight": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Human Assassin • Human Assassin": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Demon's Dagger",
                                "imgName": "demons-dagger",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 170 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Naga's Storm",
                                "imgName": "nagas-storm",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 220 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ],
        "elfs": [
            {
                "Temple Knight • Eva's Templar": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "rightStart"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Shelter's efficiency. \n • MEN Pattern \n Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. (Not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Art of Battle Axe, Deadman's Glory \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Doom Shield",
                                "imgName": "doom-shield",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 230",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Barakiel's Axe \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Shield of Nightmare",
                                "imgName": "shield-of-nightmare",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 282",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Swordsinger • Sword Muse": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Music Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Music Mastery's additional effects. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Music Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Music Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Art of Battle Axe, Deadman's Glory \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Doom Shield",
                                "imgName": "doom-shield",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 230",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Barakiel's Axe \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Shield of Nightmare",
                                "imgName": "shield-of-nightmare",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 282",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Plains Walker • Wind Rider": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Rogue Agility's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Demon's Dagger",
                                "imgName": "demons-dagger",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 170 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Naga's Storm",
                                "imgName": "nagas-storm",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 220 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Silver Ranger • Moonlight Sentinel": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Dexterous Body's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Bow of Peril",
                                "imgName": "bow-of-peril",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 400 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Shyeed's Bow",
                                "imgName": "shyeeds-bow",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 570 \n M. Atk.: 151",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Circlet (Light)",
                                "imgName": "majestic-circlet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n MP Recovery Rate +5.5% \n P. Atk. +8% when using a bow \n P. Atk. +8% when using firearms",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Heavy)",
                                "imgName": "majestic-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +7 \n Atk. Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: Speed +4, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: Speed +7, Atk. Spd. +50.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Spellsinger • Mystic Muse": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Elemental Summoner • Elemental Master": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Elven Elder • Eva's Saint": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "right"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "right"
                            },
                            {
                                "name": "MEN Pattern",
                                "imgName": "men-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Elf Death Messenger • Elf Death Knight": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ],
        "darkelfs": [
            {
                "Shillien Knight • Shillien Templar": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "rightStart"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability. \n • MEN Pattern \n Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. (Not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability and Phoenix Shield's efficiency.",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Art of Battle Axe, Deadman's Glory \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Doom Shield",
                                "imgName": "doom-shield",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 230",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Barakiel's Axe \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Shield of Nightmare",
                                "imgName": "shield-of-nightmare",
                                "type": "Shield",
                                "type2": "Shield",
                                "description": "Shield Defense: 282",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Bladedancer • Spectral Dancer": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Dance Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Dance Mastery's additional effects. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Dance Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Assists with obtaining Dance Mastery's additional effects. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Samurai Long Sword*Samurai Long Sword",
                                "imgName": "samurai-long-sword-samurai-long-sword",
                                "type": "Dual Swords",
                                "type2": "Dual Swords / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Tallum Blade*Damascus Sword",
                                "imgName": "tallum-blade-damascus-sword",
                                "type": "Dual Swords",
                                "type2": "Dual Swords / Two-handed",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Abyss Walker • Ghost Hunter": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Rogue Agility's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Demon's Dagger",
                                "imgName": "demons-dagger",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 170 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Naga's Storm",
                                "imgName": "nagas-storm",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 220 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Phantom Ranger • Ghost Sentinel": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Dexterous Body's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Bow of Peril",
                                "imgName": "bow-of-peril",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 400 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Shyeed's Bow",
                                "imgName": "shyeeds-bow",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 570 \n M. Atk.: 151",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Circlet (Light)",
                                "imgName": "majestic-circlet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n MP Recovery Rate +5.5% \n P. Atk. +8% when using a bow \n P. Atk. +8% when using firearms",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Heavy)",
                                "imgName": "majestic-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +7 \n Atk. Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: Speed +4, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: Speed +7, Atk. Spd. +50.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Spellhowler • Storm Screamer": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Phantom Summoner • Spectral Master": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Shillien Elder • Shillien Saint": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "P./ M. Def or P./ M. Atk.",
                                "optionalbar": "",
                                "optional": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Skill damage bonus.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Received Skill damage reduction or Skill damage bonus.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "right"
                            },
                            {
                                "name": "CON Pattern",
                                "imgName": "con-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "• CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Received Skill Critical Damage reduction or Skill Critical Damage or Skill Critical Rate. \n Late game: Received Skill Critical Damage reduction or Skill Critical Damage.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "right"
                            },
                            {
                                "name": "MEN Pattern",
                                "imgName": "men-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: MEN bonus. \n Additional effect: M. Def. bonus, received M. Skill Damage reduction. \n Improves survivability. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot Damage Resistance or Soulshot/ Spiritshot damage. \n Late game: Damage Decrease or Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • CON Pattern \n Effect: CON bonus. \n Additional effect: P. Def. bonus, received P. Skill Damage reduction. \n Improves survivability.",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Dark Elf Death Messenger • Dark Elf Death Knight": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Damascus Sword",
                                "imgName": "damascus-sword",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sirra's Blade",
                                "imgName": "sirras-blade",
                                "type": "Sword",
                                "type2": "Sword / One-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Dark Elf Assassin • Dark Elf Assassin": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Demon's Dagger",
                                "imgName": "demons-dagger",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 170 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Naga's Storm",
                                "imgName": "nagas-storm",
                                "type": "Dagger",
                                "type2": "Dagger / One-handed",
                                "description": "P. Atk.: 220 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            }, 
        ],
        "orcs": [
            {
                "Destroyer • Titan": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Guardian Sword",
                                "imgName": "guardian-sword",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Star Buster \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sword of Ipos",
                                "imgName": "sword-of-ipos",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Behemoth's Tuning Fork \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Tyrant • Grand Khavatari": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Bellion Cestus",
                                "imgName": "bellion-cestus",
                                "type": "Fist",
                                "type2": "Fist / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sobekk's Hurricane",
                                "imgName": "sobekks-hurricane",
                                "type": "Fist",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Dragoon • Vanguard Rider": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Lance",
                                "imgName": "lance",
                                "type": "Spear",
                                "type2": "Spear / Two-handed",
                                "description": "P. Atk.: 194 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Tiphon's Spear",
                                "imgName": "tiphons-spear",
                                "type": "Spear",
                                "type2": "Spear / Two-handed",
                                "description": "P. Atk.: 251 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Overlord • Dominator": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability.",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Warcryer • Doomcryer": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Great Fury).",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Great Fury).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Great Fury).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior after learning Great Fury).",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Staff of Evil Spirits",
                                "imgName": "staff-of-evil-spirits",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 189 \n M. Atk.: 163",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Wizard's Tear or Kaim Vanul's Bones \n Allows to equip a Shield at the cost of less damage output capability. \n • If playing as warrior after learning Great Fury, weapon can be replaced for: \n Bow of Peril (Ranged auto attack). \n Samurai Long Sword*Samurai Long Sword, Guardian Sword, Star Buster \n (Fatal Strike + Melee auto attack). \n • If playing as warrior after learning Great Fury, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Critical Rate or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "right"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Great Fury: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Great Fury: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Great Fury: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Great Fury: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior after learning Great Fury: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Daimon Crystal",
                                "imgName": "daimon-crystal",
                                "type": "Magic Blunt Weapon",
                                "type2": "Magic Blunt Weapon / Two-handed",
                                "description": "P. Atk.: 245 \n M. Atk.: 201",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Themis' Tongue, Cabrio's Hand \n Allows to equip a Shield at the cost of less damage output capability. \n • If playing as warrior after learning Great Fury, weapon can be replaced for: \n Shyeed's Bow (Ranged auto attack). \n Tallum Blade*Damascus Sword, Sword of Ipos, Behemoth's Tuning Fork \n (Fatal Strike + Melee auto attack). \n • If playing as warrior after learning Great Fury, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Critical Rate or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%. \n • If playing as warrior after learning Great Fury: \n Can be replaced for Leather Armor of Nightmare or Majestic Leather Armor.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Great Fury: \n Can be replaced for Majestic Circlet (Light) (only if using Bow) or Helm of Nightmare (Heavy).",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior after learning Great Fury: \n Can be replaced for Gauntlets of Nightmare (Light) or Majestic Gauntlets (Heavy).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability). \n • If playing as warrior after learning Great Fury: \n Can be replaced for Boots of Nightmare (Light) or Majestic Boots (Heavy).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ],
        "dwarfs": [
            {
                "Bounty Hunter • Fortune Seeker": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Guardian Sword",
                                "imgName": "guardian-sword",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Star Buster \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy. \n • Damascus Sword or Art of Battle Axe, Deadman's Glory \n Allows to equip a Shield at the cost of less damage output capability. \n • Lance \n Allows to use Spear's AoE skills, useful in PvE.",
                                "position": "right"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sword of Ipos",
                                "imgName": "sword-of-ipos",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Behemoth's Tuning Fork \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy. \n • Sirra's Blade or Barakiel's Axe \n Allows to equip a Shield at the cost of less damage output capability. \n • Tiphon's Spear \n Allows to use Spear's AoE skills, useful in PvE.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Warsmith • Maestro": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Guardian Sword",
                                "imgName": "guardian-sword",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 236 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Star Buster \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy. \n • Damascus Sword or Art of Battle Axe, Deadman's Glory \n Allows to equip a Shield at the cost of less damage output capability. \n • Lance \n Allows to use Spear's AoE skills, useful in PvE.",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Sword of Ipos",
                                "imgName": "sword-of-ipos",
                                "type": "Sword",
                                "type2": "Sword / Two-handed",
                                "description": "P. Atk.: 305 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Normal \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Behemoth's Tuning Fork \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy. \n • Sirra's Blade or Barakiel's Axe \n Allows to equip a Shield at the cost of less damage output capability. \n • Tiphon's Spear \n Allows to use Spear's AoE skills, useful in PvE.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Behemoth's Tuning Fork \n Same P. Atk., lower P. Critical Rate, higher P. Accuracy.",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ],
        "kamael": [
            {
                "Berserker • Doombringer": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Dismantler",
                                "imgName": "dismantler",
                                "type": "Ancient Sword",
                                "type2": "Ancient Sword / Two-handed",
                                "description": "P. Atk.: 214 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Breastplate",
                                "imgName": "blue-wolf-breastplate",
                                "type": "Upper armor",
                                "type2": "Upper armor / Heavy Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gaiters",
                                "imgName": "blue-wolf-gaiters",
                                "type": "Lower armor",
                                "type2": "Lower armor / Heavy Armor",
                                "description": "P. Def.: 104",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Helmet (Heavy)",
                                "imgName": "blue-wolf-helmet-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 66",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "right"
                            },
                            {
                                "name": "Doom Gloves (Light)",
                                "imgName": "doom-gloves-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Heavy) \n Combined with Blue Wolf Boots (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Doom Boots (Light)",
                                "imgName": "doom-boots-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Heavy) \n Combined with Blue Wolf Gloves (Heavy). \n Earned bonus: CON +1, Shock Resistance +30%, DEX +1, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26%, P. Atk. +2.7%.",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Durendal",
                                "imgName": "durendal",
                                "type": "Ancient Sword",
                                "type2": "Ancient Sword / Two-handed",
                                "description": "P. Atk.: 277 \n M. Atk.: 137",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Armor of Nightmare",
                                "imgName": "armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Heavy Armor",
                                "description": "P. Def.: 293",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +200 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Plate Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Helm of Nightmare (Heavy)",
                                "imgName": "helm-of-nightmare-heavy",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n HP Recovery Rate +8% \n Reflects 5% of the received damage.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Boots of Nightmare (Light)",
                                "imgName": "boots-of-nightmare-light",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Boots (Heavy) \n Earned bonus: Speed +7, Atk. Spd. +50. \n Lost bonus: Speed +4, Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Soul Ranger • Trickster": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Dexterous Body's additional effects. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Bow of Peril",
                                "imgName": "bow-of-peril",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 400 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Shyeed's Bow",
                                "imgName": "shyeeds-bow",
                                "type": "Bow",
                                "type2": "Bow / Two-handed",
                                "description": "P. Atk.: 570 \n M. Atk.: 151",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Circlet (Light)",
                                "imgName": "majestic-circlet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n MP Recovery Rate +5.5% \n P. Atk. +8% when using a bow \n P. Atk. +8% when using firearms",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Heavy)",
                                "imgName": "majestic-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +7 \n Atk. Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: Speed +4, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: Speed +7, Atk. Spd. +50.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
            {
                "Soul Breaker • Soul Hound": [
                    {
                        "dyes": [
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior).",
                                "position": "rightStart"
                            },
                            {
                                "name": "WIT Pattern",
                                "imgName": "wit-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: WIT bonus. \n Additional effect: Spiritshot damage bonus, received M. Critical Rate reduction. \n Assists with obtaining Stat Bonus WIT <40+>: M. Critical Rate +50. \n Assists with obtaining Stat Bonus WIT <50+>: M. Skill Cooldown -10%.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• INT Pattern \n Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output. \n • STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior). \n • DEX Pattern \n Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (If playing as warrior, not recommended on Early game).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior).",
                                "position": "right"
                            },
                            {
                                "name": "INT Pattern",
                                "imgName": "int-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: INT bonus. \n Additional effect: M. Atk. bonus, M. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output. \n (If playing as warrior).",
                                "position": "rightEnd"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Colichemarde",
                                "imgName": "colichemarde",
                                "type": "Rapier",
                                "type2": "Rapier / One-handed",
                                "description": "P. Atk.: 185 \n M. Atk.: 135",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Skill Critical Rate, Physical Skill Critical Damage or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "right"
                            }
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Blue Wolf Tunic",
                                "imgName": "blue-wolf-tunic",
                                "type": "Upper armor",
                                "type2": "Upper armor / Robe",
                                "description": "MP Increase: 377 \n P. Def.: 83",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Stockings",
                                "imgName": "blue-wolf-stockings",
                                "type": "Lower armor",
                                "type2": "Lower armor / Robe",
                                "description": "MP Increase: 236 \n P. Def.: 52",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Tunic \n Blue Wolf Stockings \n Blue Wolf Helmet (Robe) \n Blue Wolf Gloves (Robe) \n Blue Wolf Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: INT -2, MEN -1, WIT +3, MP Recovery Rate +5.24% \n 3 Piece: INT +1, Max MP +206 \n 4 Piece: INT +1, Shock Resistance +30% \n 5 Piece: MEN +1",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "right"
                            },
                            {
                                "name": "Avadon Circlet (Robe)",
                                "imgName": "avadon-circlet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Robe) \n Combined with Blue Wolf Gloves (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Gloves (Robe)",
                                "imgName": "avadon-gloves-robe",
                                "type2": "Gloves",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Gloves (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Boots (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Avadon Boots (Robe)",
                                "imgName": "avadon-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 41",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Avadon Robe \n Avadon Circlet (Robe) \n Avadon Gloves (Robe) \n Avadon Boots (Robe)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: Infection Resistance +60% \n 3 Piece: P. Def. +5.26%, Casting Spd. +10% \n 4 Piece:  Casting Spd. +5%",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Boots (Robe) \n Combined with Blue Wolf Helmet (Robe) and Blue Wolf Gloves (Robe). \n Earned bonus: INT +2, Max MP +206, Shock Resistance +30%, MEN +1. \n Lost bonus: Infection Resistance +60%, P. Def. +5.26%, Casting Spd. +10%. \n (Final P. Def.'s difference is approximately 1%, due to higher P. Def of Blue Wolf armors). \n • If playing as warrior: \n Set can be  replaced for: Blue Wolf Heavy (3 pcs.) + Doom Light (2 pcs.).",
                                "position": "rightEnd"
                            }
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Éclair Bijou",
                                "imgName": "eclair-bijou",
                                "type": "Rapier",
                                "type2": "Rapier / One-handed",
                                "description": "P. Atk.: 239 \n M. Atk.: 165",
                                "description2": "Atk. Spd.: Fast \n Soulshots Used: X1 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Magical Skill Critical Rate",
                                "adensc2": "Magical Skill Critical Damage or Magic Attack or Magical Speed",
                                "hardinsc": "Magical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior, Aden's Soul Crystals effects can be replaced for: \n Physical Attack, Physical Skill Critical Rate, Physical Skill Critical Damage or Physical Speed. \n Hardin's Soul Crystal effect can be replaced for Physical Abilities.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Majestic Robe",
                                "imgName": "majestic-robe",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Robe",
                                "description": "MP Increase: 838 \n P. Def.: 147",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "WIT +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +100 \n Shock Resistance +50%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Robe of Nightmare \n Earned bonus: Sleep Resistance +70%, Hold Resistance +70%. \n Lost bonus: Shock Resistance +50%. \n • If playing as warrior: \n Can be replaced for Leather Armor of Nightmare or Majestic Leather Armor.",
                                "position": "right"
                            },
                            {
                                "name": "Dark Crystal Helmet (Robe)",
                                "imgName": "dark-crystal-helmet-robe",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 69",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "MEN +1 \n Overweight limit +1500 \n M. Def. +10 \n M. Atk. +8% \n MP Recovery Rate +5.5% \n P. Def. +8%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior: \n Can be replaced for Helm of Nightmare (Heavy).",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Gauntlets (Robe)",
                                "imgName": "majestic-gauntlets-robe",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Casting Spd. +15% \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• If playing as warrior: \n Can be replaced for Gauntlets of Nightmare (Light) or Majestic Gauntlets (Heavy).",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Robe)",
                                "imgName": "majestic-boots-robe",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "INT +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +4 \n Casting Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: STR +1, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: INT +1, Casting Spd. +50. \n (Often used in PvE to improve survivability). \n • If playing as warrior: \n Can be replaced for Boots of Nightmare (Light) or Majestic Boots (Heavy).",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ],
        "sylph": [
            {
                "Wind Sniper • Storm Blaster": [
                    {
                        "dyes": [
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 1",
                                "type2": "Pattern 1",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: P./ M. Atk. or Atk. Spd./ Casting Spd. \n Late game: P./ M. Atk.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "DEX Pattern",
                                "imgName": "dex-pattern",
                                "type": "Pattern 2",
                                "type2": "Pattern 2",
                                "description": "Effect: DEX bonus. \n Additional effect: Soulshot damage bonus, received P. Skill Critical Rate reduction. \n Assists with obtaining Stat Bonus DEX <60+>: P. Skill Cooldown -10%. \n (Not recommended on Early game).",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Skill damage bonus.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• STR Pattern \n Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 3",
                                "type2": "Pattern 3",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "",
                                "hiddenpower": "Early game: Skill Critical Damage or Skill Critical Rate. \n Late game: Skill Critical Damage.",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "STR Pattern",
                                "imgName": "str-pattern",
                                "type": "Pattern 4",
                                "type2": "Pattern 4",
                                "description": "Effect: STR bonus. \n Additional effect: P. Atk. bonus, P. Skill Critical Rate bonus. \n Improves damage output.",
                                "description2": "-",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpower": "-",
                                "hiddenpowerpvebar": "",
                                "hiddenpowerpve": "Soulshot/ Spiritshot damage.",
                                "hiddenpowerpvpbar": "",
                                "hiddenpowerpvp": "Early game: Soulshot/ Spiritshot damage. \n Late game: Damage Decrease Ignore.",
                                "optionalbar": "-",
                                "position": "right"
                            },
                        ]
                    },
                    {
                        "weaponB": [
                            {
                                "name": "Volcano",
                                "imgName": "volcano",
                                "type": "Firearms",
                                "type2": "Firearms / Two-handed",
                                "description": "P. Atk.: 400 \n M. Atk.: 111",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorB": [
                            {
                                "name": "Doom Leather Armor",
                                "imgName": "doom-leather-armor",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 166",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optional": "• Blue Wolf Breastplate and Blue Wolf Gaiters \n Combined with Blue Wolf Helmet (Heavy). \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Doom Helmet (Light)",
                                "imgName": "doom-helmet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 62",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Doom Leather Armor \n Doom Helmet (Light) \n Doom Gloves (Light) \n Doom Boots (Light)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7% \n 3 Piece: DEX +1, Sleep/ Hold Resistance +50% \n 4 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Blue Wolf Helmet (Heavy) \n Combined with Blue Wolf Breastplate and Blue Wolf Gaiters. \n Earned bonus: DEX +2, Speed +7, CON +1, Shock Resistance +30%, M. Def. +44. \n Lost bonus: DEX +1, MP Recovery Rate +5.26, P. Atk. +2.7%. \n Light Armor Mastery's bonus is lost (very useful on this Class).",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Gloves (Heavy)",
                                "imgName": "blue-wolf-gloves-heavy",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Blue Wolf Boots (Heavy)",
                                "imgName": "blue-wolf-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 44",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "",
                                "setitems": "Blue Wolf Breastplate \n Blue Wolf Gaiters \n Blue Wolf Helmet (Heavy) \n Blue Wolf Gloves (Heavy) \n Blue Wolf Boots (Heavy)",
                                "seteffectbar": "",
                                "seteffect": "2 Piece: STR +3, CON -1, DEX -2, HP Recovery Rate +5.24% \n 3 Piece: DEX +1, Speed +7 \n 4 Piece: CON +1, Shock Resistance +30% \n 5 Piece: DEX +1, M. Def. +44",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "optional": "-",
                                "position": "rightEnd"
                            },
                        ],
                    },
                    {
                        "weaponA": [
                            {
                                "name": "Zephyrus",
                                "imgName": "zephyrus",
                                "type": "Firearms",
                                "type2": "Firearms / Two-handed",
                                "description": "P. Atk.: 570 \n M. Atk.: 151",
                                "description2": "Atk. Spd.: Slow \n Soulshots Used: X2 \n Spiritshots Used: X1",
                                "soulcrystalsbar": "",
                                "adensc1": "PvP: Health Increase / PvE: Physical Skill Critical Rate",
                                "adensc2": "Physical Skill Critical Damage or Physical Attack or Physical Speed",
                                "hardinsc": "Physical Abilities",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                            {
                                "name": "Majestic Sigil",
                                "imgName": "majestic-sigil",
                                "type": "Sigil",
                                "type2": "Sigil",
                                "description": "P. Def.: 43",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "-",
                                "properties": "-",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "rightStart"
                            },
                        ],
                    },
                    {
                        "armorA": [
                            {
                                "name": "Leather Armor of Nightmare",
                                "imgName": "leather-armor-of-nightmare",
                                "type": "Upper and Lower Body",
                                "type2": "Upper and Lower Body / Light Armor",
                                "description": "P. Def.: 220",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "DEX +2 \n Overweight limit +2500 \n M. Def. +25 \n Max HP +150 \n Max MP +80 \n Sleep Resistance +70% \n Hold Resistance +70%",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Leather Armor \n Earned bonus: Shock Resistance +50%. \n Lost bonus: Sleep Resistance +70%, Hold Resistance +70%.",
                                "position": "right"
                            },
                            {
                                "name": "Majestic Circlet (Light)",
                                "imgName": "majestic-circlet-light",
                                "type": "Headgear",
                                "type2": "Headgear",
                                "description": "P. Def.: 73",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "CON +1 \n Overweight limit +1500 \n M. Def. +10 \n P. Atk. +4% \n MP Recovery Rate +5.5% \n P. Atk. +8% when using a bow \n P. Atk. +8% when using firearms",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "-",
                                "position": "right"
                            },
                            {
                                "name": "Gauntlets of Nightmare (Light)",
                                "imgName": "gauntlets-of-nightmare-light",
                                "type": "Gloves",
                                "type2": "Gloves",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Atk. Spd. +8% \n Absorbs 2% of the inflicted damage as HP.",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Majestic Gauntlets (Heavy) \n Earned bonus: Atk. Spd. +50. \n Lost bonus: Absorbs 2% of the inflicted damage as HP.",
                                "position": "rightEnd"
                            },
                            {
                                "name": "Majestic Boots (Heavy)",
                                "imgName": "majestic-boots-heavy",
                                "type": "Boots",
                                "type2": "Boots",
                                "description": "P. Def.: 49",
                                "soulcrystalsbar": "-",
                                "adensc1": "-",
                                "adensc2": "-",
                                "hardinsc": "-",
                                "setitemsbar": "-",
                                "setitems": "-",
                                "seteffectbar": "-",
                                "seteffect": "-",
                                "propertiesbar": "",
                                "properties": "STR +1 \n Overweight limit +1000 \n M. Def. +10 \n Max HP +50 \n Speed +7 \n Atk. Spd. +50",
                                "hiddenpowerbar": "-",
                                "hiddenpowerpvebar": "-",
                                "hiddenpowerpve": "-",
                                "hiddenpowerpvpbar": "-",
                                "hiddenpowerpvp": "-",
                                "optionalbar": "",
                                "optional": "• Boots of Nightmare (Light) \n Earned bonus: Speed +4, Absorbs 2% of the inflicted damage as HP. \n Lost bonus: Speed +7, Atk. Spd. +50.",
                                "position": "rightEnd"
                            },
                        ],
                    }
                ]
            },
        ]
    }
             
]
        

export default classGuides