export const TALISMAN_OF_ADEN_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "80%",
  },
  {
    "Value": "+3",
    "Probability": "70%",
  },
  {
    "Value": "+4",
    "Probability": "60%",
  },
  {
    "Value": "+5",
    "Probability": "40%",
  },
  {
    "Value": "+6",
    "Probability": "30%",
  },
  {
    "Value": "+7",
    "Probability": "24%",
  },
  {
    "Value": "+8",
    "Probability": "16%",
  },
  {
    "Value": "+9",
    "Probability": "10%",
  },
  {
    "Value": "+10",
    "Probability": "10%",
  },
]

export const TALISMAN_OF_ADEN_V1 = [
  {
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "Atk. Spd.": "-",
    "Casting. Spd.": "-",
    "Max HP": "-",
    "Max MP": "-",
    "Acquired XP": "-",
    "Acquired SP": "-",
  },
  {
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+5",
    "M. Def.": "+5",
    "Atk. Spd.": "+10",
    "Casting. Spd.": "+10",
    "Max HP": "+100",
    "Max MP": "+30",
    "Acquired XP": "-",
    "Acquired SP": "-",
  },
  {
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+10",
    "M. Def.": "+10",
    "Atk. Spd.": "+12",
    "Casting. Spd.": "+12",
    "Max HP": "+120",
    "Max MP": "+50",
    "Acquired XP": "-",
    "Acquired SP": "-",
  },
  {
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+15",
    "M. Def.": "+20",
    "Atk. Spd.": "+15",
    "Casting. Spd.": "+15",
    "Max HP": "+150",
    "Max MP": "+70",
    "Acquired XP": "-",
    "Acquired SP": "-",
  },
  {
    "P. Atk.": "-",
    "M. Atk.": "-",
    "P. Def.": "+20",
    "M. Def.": "+30",
    "Atk. Spd.": "+20",
    "Casting. Spd.": "+20",
    "Max HP": "+180",
    "Max MP": "+100",
    "Acquired XP": "+10%",
    "Acquired SP": "+10%",
  },
  {
    "P. Atk.": "+50",
    "M. Atk.": "+100",
    "P. Def.": "+30",
    "M. Def.": "+40",
    "Atk. Spd.": "+25",
    "Casting. Spd.": "+25",
    "Max HP": "+220",
    "Max MP": "+130",
    "Acquired XP": "+15%",
    "Acquired SP": "+15%",
  },
  {
    "P. Atk.": "+80",
    "M. Atk.": "+150",
    "P. Def.": "+40",
    "M. Def.": "+60",
    "Atk. Spd.": "+40",
    "Casting. Spd.": "+45",
    "Max HP": "+260",
    "Max MP": "+160",
    "Acquired XP": "+20%",
    "Acquired SP": "+20%",
  },
  {
    "P. Atk.": "+135",
    "M. Atk.": "+250",
    "P. Def.": "+60",
    "M. Def.": "+80",
    "Atk. Spd.": "+70",
    "Casting. Spd.": "+75",
    "Max HP": "+520",
    "Max MP": "+320",
    "Acquired XP": "+50%",
    "Acquired SP": "+50%",
  },
  {
    "P. Atk.": "+180",
    "M. Atk.": "+360",
    "P. Def.": "+80",
    "M. Def.": "+120",
    "Atk. Spd.": "+80",
    "Casting. Spd.": "+95",
    "Max HP": "+640",
    "Max MP": "+360",
    "Acquired XP": "+55%",
    "Acquired SP": "+55%",
  },
  {
    "P. Atk.": "+225",
    "M. Atk.": "+420",
    "P. Def.": "+120",
    "M. Def.": "+160",
    "Atk. Spd.": "+90",
    "Casting. Spd.": "+110",
    "Max HP": "+910",
    "Max MP": "+430",
    "Acquired XP": "+60%",
    "Acquired SP": "+60%",
  },
  {
    "P. Atk.": "+270",
    "M. Atk.": "+490",
    "P. Def.": "+160",
    "M. Def.": "+200",
    "Atk. Spd.": "+100",
    "Casting. Spd.": "+125",
    "Max HP": "+1120",
    "Max MP": "+530",
    "Acquired XP": "+65%",
    "Acquired SP": "+65%",
  }
]