export const AGATHION_BRACELET_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 3",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 4",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 5",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 6",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 7",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 8",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 9",
    "Probability": "25%",
  },
  {
    "Value": "Lv. 10",
    "Probability": "25%",
  },
]

export const AGATHION_BRACELET_V1 = [
  {
    "Agathion slots": "+4",
    "Paralysis Resistance": "-",
    "Shock Resistance": "-",
    "Sleep Resistance": "-",
    "Silence Resistance": "-",
    "Fear Resistance": "-",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "-",
    "INT": "-",
    "DEX": "-",
    "WIT": "-",
    "CON": "-",
    "MEN": "-",
  },
  {
    "Agathion slots": "+4",
    "Paralysis Resistance": "+2%",
    "Shock Resistance": "+2%",
    "Sleep Resistance": "+2%",
    "Silence Resistance": "+2%",
    "Fear Resistance": "+2%",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "-",
    "INT": "-",
    "DEX": "-",
    "WIT": "-",
    "CON": "-",
    "MEN": "-",
  },
  {
    "Agathion slots": "+4",
    "Paralysis Resistance": "+3%",
    "Shock Resistance": "+3%",
    "Sleep Resistance": "+3%",
    "Silence Resistance": "+3%",
    "Fear Resistance": "+3%",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "-",
    "INT": "-",
    "DEX": "-",
    "WIT": "-",
    "CON": "-",
    "MEN": "-",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+5%",
    "Shock Resistance": "+5%",
    "Sleep Resistance": "+5%",
    "Silence Resistance": "+5%",
    "Fear Resistance": "+5%",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "+1",
    "INT": "+1",
    "DEX": "-",
    "WIT": "-",
    "CON": "+1",
    "MEN": "+1",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+8%",
    "Shock Resistance": "+8%",
    "Sleep Resistance": "+8%",
    "Silence Resistance": "+8%",
    "Fear Resistance": "+8%",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "+1",
    "INT": "+1",
    "DEX": "+1",
    "WIT": "+1",
    "CON": "+1",
    "MEN": "+1",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+10%",
    "Shock Resistance": "+10%",
    "Sleep Resistance": "+10%",
    "Silence Resistance": "+10%",
    "Fear Resistance": "+10%",
    "Hold Resistance": "-",
    "Pull Resistance": "-",
    "Imprisonment Resistance": "-",
    "Knockback Resistance": "-",
    "Suppression Resistance": "-",
    "STR": "+1",
    "INT": "+1",
    "DEX": "+2",
    "WIT": "+2",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+12%",
    "Shock Resistance": "+12%",
    "Sleep Resistance": "+12%",
    "Silence Resistance": "+12%",
    "Fear Resistance": "+12%",
    "Hold Resistance": "+5%",
    "Pull Resistance": "+5%",
    "Imprisonment Resistance": "+5%",
    "Knockback Resistance": "+5%",
    "Suppression Resistance": "+5%",
    "STR": "+2",
    "INT": "+2",
    "DEX": "+2",
    "WIT": "+2",
    "CON": "+2",
    "MEN": "+2",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+15%",
    "Shock Resistance": "+15%",
    "Sleep Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
    "Hold Resistance": "+10%",
    "Pull Resistance": "+10%",
    "Imprisonment Resistance": "+10%",
    "Knockback Resistance": "+10%",
    "Suppression Resistance": "+10%",
    "STR": "+2",
    "INT": "+2",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+3",
    "MEN": "+3",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+15%",
    "Shock Resistance": "+15%",
    "Sleep Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
    "Hold Resistance": "+10%",
    "Pull Resistance": "+10%",
    "Imprisonment Resistance": "+10%",
    "Knockback Resistance": "+10%",
    "Suppression Resistance": "+10%",
    "STR": "+3",
    "INT": "+3",
    "DEX": "+3",
    "WIT": "+3",
    "CON": "+3",
    "MEN": "+3",
  },
  {
    "Agathion slots": "+5",
    "Paralysis Resistance": "+15%",
    "Shock Resistance": "+15%",
    "Sleep Resistance": "+15%",
    "Silence Resistance": "+15%",
    "Fear Resistance": "+15%",
    "Hold Resistance": "+10%",
    "Pull Resistance": "+10%",
    "Imprisonment Resistance": "+10%",
    "Knockback Resistance": "+10%",
    "Suppression Resistance": "+10%",
    "STR": "+4",
    "INT": "+4",
    "DEX": "+4",
    "WIT": "+4",
    "CON": "+4",
    "MEN": "+4",
  },
]