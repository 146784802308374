export const TALISMAN_OF_HELLBOUND_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
    "Failure": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "60%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 3",
    "Probability": "50%",
    "Failure": "Lv. 1",
  },
  {
    "Value": "Lv. 4",
    "Probability": "50%",
    "Failure": "Lv. 2",
  },
  {
    "Value": "Lv. 5",
    "Probability": "40%",
    "Failure": "Lv. 3",
  },
  {
    "Value": "Lv. 6",
    "Probability": "40%",
    "Failure": "Lv. 4",
  },
  {
    "Value": "Lv. 7",
    "Probability": "30%",
    "Failure": "Lv. 5",
  },
  {
    "Value": "Lv. 8",
    "Probability": "30%",
    "Failure": "Lv. 6",
  },
  {
    "Value": "Lv. 9",
    "Probability": "20%",
    "Failure": "Lv. 7",
  },
  {
    "Value": "Lv. 10",
    "Probability": "10%",
    "Failure": "Lv. 8",
  },
]

export const TALISMAN_OF_HELLBOUND_V1 = [
  {
    "Max HP": "+100",
    "P. Atk.": "+50",
    "M. Atk.": "+50",
    "P. Def.": "+100",
    "M. Def.": "+100",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "Speed": "-",
    "P. Accuracy": "-",
    "M. Accuracy": "-",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+200",
    "P. Atk.": "+100",
    "M. Atk.": "+100",
    "P. Def.": "+200",
    "M. Def.": "+200",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "Speed": "-",
    "P. Accuracy": "+1",
    "M. Accuracy": "+1",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+300",
    "P. Atk.": "+150",
    "M. Atk.": "+150",
    "P. Def.": "+300",
    "M. Def.": "+300",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "Speed": "-",
    "P. Accuracy": "+2",
    "M. Accuracy": "+2",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+400",
    "P. Atk.": "+200",
    "M. Atk.": "+200",
    "P. Def.": "+400",
    "M. Def.": "+400",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "Speed": "-",
    "P. Accuracy": "+3",
    "M. Accuracy": "+3",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+500",
    "P. Atk.": "+250",
    "M. Atk.": "+250",
    "P. Def.": "+500",
    "M. Def.": "+500",
    "Atk. Spd.": "+35",
    "Casting Spd.": "+35",
    "Speed": "-",
    "P. Accuracy": "+4",
    "M. Accuracy": "+4",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+700",
    "P. Atk.": "+300",
    "M. Atk.": "+300",
    "P. Def.": "+600",
    "M. Def.": "+600",
    "Atk. Spd.": "+50",
    "Casting Spd.": "+50",
    "Speed": "+1",
    "P. Accuracy": "+5",
    "M. Accuracy": "+5",
    "Received PvP damage": "-",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+1000",
    "P. Atk.": "+350",
    "M. Atk.": "+350",
    "P. Def.": "+700",
    "M. Def.": "+700",
    "Atk. Spd.": "+70",
    "Casting Spd.": "+70",
    "Speed": "+2",
    "P. Accuracy": "+6",
    "M. Accuracy": "+6",
    "Received PvP damage": "-3%",
    "Debuff Resistance": "-",
    "Anomaly Resistance": "-",
  },
  {
    "Max HP": "+2000",
    "P. Atk.": "+400",
    "M. Atk.": "+400",
    "P. Def.": "+800",
    "M. Def.": "+800",
    "Atk. Spd.": "+100",
    "Casting Spd.": "+100",
    "Speed": "+5",
    "P. Accuracy": "+7",
    "M. Accuracy": "+7",
    "Received PvP damage": "-7%",
    "Debuff Resistance": "+2%",
    "Anomaly Resistance": "+2%",
  },
  {
    "Max HP": "+3000",
    "P. Atk.": "+450",
    "M. Atk.": "+450",
    "P. Def.": "+900",
    "M. Def.": "+900",
    "Atk. Spd.": "+150",
    "Casting Spd.": "+150",
    "Speed": "+7",
    "P. Accuracy": "+8",
    "M. Accuracy": "+8",
    "Received PvP damage": "-12%",
    "Debuff Resistance": "+5%",
    "Anomaly Resistance": "+5%",
  },
  {
    "Max HP": "+5000",
    "P. Atk.": "+500",
    "M. Atk.": "+500",
    "P. Def.": "+1000",
    "M. Def.": "+1000",
    "Atk. Spd.": "+200",
    "Casting Spd.": "+200",
    "Speed": "+10",
    "P. Accuracy": "+10",
    "M. Accuracy": "+10",
    "Received PvP damage": "-20%",
    "Debuff Resistance": "+10%",
    "Anomaly Resistance": "+10%",
  },
]

export const TALISMAN_OF_HELLBOUND_TRIGGER_V1 = [
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "-",
    "Cooldown": "-",
  },
  {
    "Effect": "Deals 1000 fixed damage.",
    "Cooldown": "300 sec.",
  },
  {
    "Effect": "Deals 1500 fixed damage.",
    "Cooldown": "300 sec.",
  },
  {
    "Effect": "Deals 2000 fixed damage.",
    "Cooldown": "300 sec.",
  },
  {
    "Effect": "Deals 3000 fixed damage.",
    "Cooldown": "300 sec.",
  },
  {
    "Effect": "Deals 4000 fixed damage.",
    "Cooldown": "300 sec.",
  },
  {
    "Effect": "Deals 5000 fixed damage.",
    "Cooldown": "300 sec.",
  }
]