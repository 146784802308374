export const GRAN_KAINS_PENDANT_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "50%",
  },
  {
    "Value": "+2",
    "Probability": "30%",
  },
  {
    "Value": "+3",
    "Probability": "20%",
  },
  {
    "Value": "+4",
    "Probability": "10%",
  },
  {
    "Value": "+5",
    "Probability": "3%",
  },
]

export const GRAN_KAINS_PENDANT_V1 = [
  {
    "M. Def.": "+60",
    "Acquired XP/ SP": "+50%",
    "Received damage": "-25%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+10%",
    "All received Critical Damage": "-",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "-",
    "Spiritshot damage": "-",
    "Damage Decrease Ignore": "-",
  },
  {
    "M. Def.": "+80",
    "Acquired XP/ SP": "+60%",
    "Received damage": "-26%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+11%",
    "All received Critical Damage": "-",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "+8%",
    "Spiritshot damage": "+8%",
    "Damage Decrease Ignore": "-",
  },
  {
    "M. Def.": "+100",
    "Acquired XP/ SP": "+70%",
    "Received damage": "-27%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+12%",
    "All received Critical Damage": "-200",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "+10%",
    "Spiritshot damage": "+10%",
    "Damage Decrease Ignore": "-",
  },
  {
    "M. Def.": "+120",
    "Acquired XP/ SP": "+80%",
    "Received damage": "-28%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+13%",
    "All received Critical Damage": "-300",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "+12%",
    "Spiritshot damage": "+12%",
    "Damage Decrease Ignore": "+200",
  },
  {
    "M. Def.": "+140",
    "Acquired XP/ SP": "+90%",
    "Received damage": "-29%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+14%",
    "All received Critical Damage": "-400",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "+14%",
    "Spiritshot damage": "+14%",
    "Damage Decrease Ignore": "+400",
  },
  {
    "M. Def.": "+200",
    "Acquired XP/ SP": "+100%",
    "Received damage": "-30%",
    "Atk. Spd.": "+10%",
    "Casting Spd.": "+10%",
    "All Critical Damage": "+15%",
    "All received Critical Damage": "-500",
    "P. Atk.": "+15%",
    "M. Atk.": "+15%",
    "Soulshot damage": "+20%",
    "Spiritshot damage": "+20%",
    "Damage Decrease Ignore": "+600",
  },
]

export const GRAN_KAINS_PENDANT_ACTIVE_V1 = [
  {
    "Effect": "Max HP +500. Speed +33. Recovers 500 HP every 3.3 seconds. Recovers 200 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
  {
    "Effect": "Max HP +1000. Speed +33. Recovers 600 HP every 3.3 seconds. Recovers 250 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
  {
    "Effect": "Max HP +2000. Speed +33. Recovers 700 HP every 3.3 seconds. Recovers 300 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
  {
    "Effect": "Max HP +3000. Speed +33. Recovers 800 HP every 3.3 seconds. Recovers 350 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
  {
    "Effect": "Max HP +4000. Speed +33. Recovers 900 HP every 3.3 seconds. Recovers 400 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
  {
    "Effect": "Max HP +5000. Speed +33. Recovers 1000 HP every 3.3 seconds. Recovers 450 MP every 3.3 seconds.",
    "Duration": "15 sec.",
    "Cooldown": "10 min.",
  },
]