export const BOOTS_OF_EVASION_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "100%",
  },
  {
    "Value": "+2",
    "Probability": "100%",
  },
  {
    "Value": "+3",
    "Probability": "100%",
  },
  {
    "Value": "+4",
    "Probability": "100%",
  },
  {
    "Value": "+5",
    "Probability": "20%",
  },
  {
    "Value": "+6",
    "Probability": "10%",
  },
  {
    "Value": "+7",
    "Probability": "10%",
  },
  {
    "Value": "+8",
    "Probability": "8%",
  },
  {
    "Value": "+9",
    "Probability": "5%",
  },
  {
    "Value": "+10",
    "Probability": "2%",
  },
]

export const BOOTS_OF_EVASION_V1 = [
  {
    "Defense Increase": "-",
    "Skill Cooldown": "-",
  },
  {
    "Defense Increase": "-",
    "Skill Cooldown": "-",
  },
  {
    "Defense Increase": "-",
    "Skill Cooldown": "-",
  },
  {
    "Defense Increase": "-",
    "Skill Cooldown": "-",
  },
  {
    "Defense Increase": "-",
    "Skill Cooldown": "-",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-1%",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-2%",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-3%",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-5%",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-7%",
  },
  {
    "Defense Increase": "Increases P./ M. Def. depending on Max HP.",
    "Skill Cooldown": "-10%",
  }
]

export const BOOTS_OF_EVASION_TRIGGER_V1 = [
  {
    "Probability": "When hit: 1%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 3%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 5%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 7%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 8%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 9%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
  {
    "Probability": "When hit: 10%",
    "Effect": "Skill Evasion +70%, P./ M. Evasion +45. Active for 5 hits.",
    "Duration": "30 sec.",
    "Cooldown": "60 sec.",
  },
]