export const CLOAK_OF_PROTECTION_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "85%",
  },
  {
    "Value": "+2",
    "Probability": "80%",
  },
  {
    "Value": "+3",
    "Probability": "75%",
  },
  {
    "Value": "+4",
    "Probability": "60%",
  },
  {
    "Value": "+5",
    "Probability": "50%",
  },
  {
    "Value": "+6",
    "Probability": "40%",
  },
  {
    "Value": "+7",
    "Probability": "30%",
  },
  {
    "Value": "+8",
    "Probability": "20%",
  },
  {
    "Value": "+9",
    "Probability": "15%",
  },
  {
    "Value": "+10",
    "Probability": "7%",
  },
]

export const CLOAK_OF_PROTECTION_V1 = [
  {
    "Max HP": "+3%",
    "Max MP": "+3%",
    "Bow Resistance": "+1%",
    "Acquired XP": "-",
    "Acquired SP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+4%",
    "Max MP": "+4%",
    "Bow Resistance": "+1%",
    "Acquired XP": "-",
    "Acquired SP": "-",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+5%",
    "Max MP": "+5%",
    "Bow Resistance": "+2%",
    "Acquired XP": "+3%",
    "Acquired SP": "+3%",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+6%",
    "Max MP": "+6%",
    "Bow Resistance": "+2%",
    "Acquired XP": "+5%",
    "Acquired SP": "+5%",
    "P. Def.": "-",
    "M. Def.": "-",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+7%",
    "Max MP": "+7%",
    "Bow Resistance": "+3%",
    "Acquired XP": "+7%",
    "Acquired SP": "+7%",
    "P. Def.": "+2%",
    "M. Def.": "+2%",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+9%",
    "Max MP": "+9%",
    "Bow Resistance": "+3%",
    "Acquired XP": "+10%",
    "Acquired SP": "+10%",
    "P. Def.": "+4%",
    "M. Def.": "+4%",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+11%",
    "Max MP": "+11%",
    "Bow Resistance": "+4%",
    "Acquired XP": "+15%",
    "Acquired SP": "+15%",
    "P. Def.": "+6%",
    "M. Def.": "+6%",
    "All Critical Damage": "-",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+13%",
    "Max MP": "+13%",
    "Bow Resistance": "+6%",
    "Acquired XP": "+20%",
    "Acquired SP": "+20%",
    "P. Def.": "+8%",
    "M. Def.": "+8%",
    "All Critical Damage": "+5%",
    "All received Critical Rate": "-",
    "All received Critical Damage": "-",
  },
  {
    "Max HP": "+15%",
    "Max MP": "+15%",
    "Bow Resistance": "+9%",
    "Acquired XP": "+25%",
    "Acquired SP": "+25%",
    "P. Def.": "+10%",
    "M. Def.": "+10%",
    "All Critical Damage": "+7%",
    "All received Critical Rate": "-3%",
    "All received Critical Damage": "-3%",
  },
  {
    "Max HP": "+18%",
    "Max MP": "+18%",
    "Bow Resistance": "+12%",
    "Acquired XP": "+30%",
    "Acquired SP": "+30%",
    "P. Def.": "+12%",
    "M. Def.": "+12%",
    "All Critical Damage": "+10%",
    "All received Critical Rate": "-5%",
    "All received Critical Damage": "-5%",
  },
  {
    "Max HP": "+20%",
    "Max MP": "+20%",
    "Bow Resistance": "+15%",
    "Acquired XP": "+30%",
    "Acquired SP": "+30%",
    "P. Def.": "+15%",
    "M. Def.": "+15%",
    "All Critical Damage": "+15%",
    "All received Critical Rate": "-10%",
    "All received Critical Damage": "-10%",
  },
]