export const BLESSED_DRAGON_BELT_ENCHANT_V1 = [
  {
    "Value": "-",
    "Probability": "-",
  },
  {
    "Value": "+1",
    "Probability": "60%",
  },
  {
    "Value": "+2",
    "Probability": "50%",
  },
  {
    "Value": "+3",
    "Probability": "40%",
  },
  {
    "Value": "+4",
    "Probability": "35%",
  },
  {
    "Value": "+5",
    "Probability": "30%",
  },
  {
    "Value": "+6",
    "Probability": "28%",
  },
  {
    "Value": "+7",
    "Probability": "24%",
  },
  {
    "Value": "+8",
    "Probability": "26%",
  },
  {
    "Value": "+9",
    "Probability": "22%",
  },
  {
    "Value": "+10",
    "Probability": "20%",
  },
]

export const BLESSED_DRAGON_BELT_V1 = [
  {
    "Weight limit": "+1000",
    "Inventory slots": "-",
    "Max HP": "+20",
    "Max MP": "+10",
    "Max CP": "+20",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "+10",
    "M. Def.": "+10",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "-",
    "DEX/ WIT": "+1",
    "CON/ MEN": "-",
  },
  {
    "Weight limit": "+2000",
    "Inventory slots": "+2",
    "Max HP": "+30",
    "Max MP": "+20",
    "Max CP": "+30",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "+15",
    "M. Def.": "+15",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "-",
    "DEX/ WIT": "+1",
    "CON/ MEN": "+1",
  },
  {
    "Weight limit": "+3000",
    "Inventory slots": "+4",
    "Max HP": "+40",
    "Max MP": "+25",
    "Max CP": "+40",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "+20",
    "M. Def.": "+20",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+1",
    "DEX/ WIT": "+1",
    "CON/ MEN": "+1",
  },
  {
    "Weight limit": "+4000",
    "Inventory slots": "+6",
    "Max HP": "+50",
    "Max MP": "+30",
    "Max CP": "+50",
    "HP Recovery Potions' Effect": "-",
    "MP Recovery Potions' Effect": "-",
    "P. Def.": "+25",
    "M. Def.": "+25",
    "Atk. Spd.": "-",
    "Casting Spd.": "-",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+2",
    "DEX/ WIT": "+2",
    "CON/ MEN": "+2",
  },
  {
    "Weight limit": "+5000",
    "Inventory slots": "+6",
    "Max HP": "+150",
    "Max MP": "+100",
    "Max CP": "+200",
    "HP Recovery Potions' Effect": "+80",
    "MP Recovery Potions' Effect": "+10",
    "P. Def.": "+30",
    "M. Def.": "+30",
    "Atk. Spd.": "+5",
    "Casting Spd.": "+10",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+3",
    "DEX/ WIT": "+3",
    "CON/ MEN": "+3",
  },
  {
    "Weight limit": "+6000",
    "Inventory slots": "+6",
    "Max HP": "+200",
    "Max MP": "+150",
    "Max CP": "+300",
    "HP Recovery Potions' Effect": "+100",
    "MP Recovery Potions' Effect": "+20",
    "P. Def.": "+40",
    "M. Def.": "+40",
    "Atk. Spd.": "+10",
    "Casting Spd.": "+20",
    "P. Critical Rate/ M. Skill Critical Rate": "-",
    "Received Healing": "-",
    "Received damage": "-",
    "STR/ INT": "+3",
    "DEX/ WIT": "+3",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+7000",
    "Inventory slots": "+6",
    "Max HP": "+300",
    "Max MP": "+200",
    "Max CP": "+500",
    "HP Recovery Potions' Effect": "+120",
    "MP Recovery Potions' Effect": "+30",
    "P. Def.": "+50",
    "M. Def.": "+50",
    "Atk. Spd.": "+20",
    "Casting Spd.": "+30",
    "P. Critical Rate/ M. Skill Critical Rate": "+10",
    "Received Healing": "-",
    "Received damage": "-2%",
    "STR/ INT": "+3",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+8000",
    "Inventory slots": "+8",
    "Max HP": "+350",
    "Max MP": "+250",
    "Max CP": "+600",
    "HP Recovery Potions' Effect": "+135",
    "MP Recovery Potions' Effect": "+40",
    "P. Def.": "+65",
    "M. Def.": "+65",
    "Atk. Spd.": "+30",
    "Casting Spd.": "+40",
    "P. Critical Rate/ M. Skill Critical Rate": "+20",
    "Received Healing": "-",
    "Received damage": "-3%",
    "STR/ INT": "+4",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+4",
  },
  {
    "Weight limit": "+9000",
    "Inventory slots": "+8",
    "Max HP": "+500",
    "Max MP": "+350",
    "Max CP": "+800",
    "HP Recovery Potions' Effect": "+175",
    "MP Recovery Potions' Effect": "+65",
    "P. Def.": "+90",
    "M. Def.": "+90",
    "Atk. Spd.": "+50",
    "Casting Spd.": "+50",
    "P. Critical Rate/ M. Skill Critical Rate": "+30",
    "Received Healing": "+5%",
    "Received damage": "-4%",
    "STR/ INT": "+4",
    "DEX/ WIT": "+4",
    "CON/ MEN": "+5",
  },
  {
    "Weight limit": "+10000",
    "Inventory slots": "+10",
    "Max HP": "+800",
    "Max MP": "+400",
    "Max CP": "+1200",
    "HP Recovery Potions' Effect": "+250",
    "MP Recovery Potions' Effect": "+90",
    "P. Def.": "+125",
    "M. Def.": "+125",
    "Atk. Spd.": "+100",
    "Casting Spd.": "+100",
    "P. Critical Rate/ M. Skill Critical Rate": "+40",
    "Received Healing": "+10%",
    "Received damage": "-7%",
    "STR/ INT": "+4",
    "DEX/ WIT": "+5",
    "CON/ MEN": "+5",
  },
  {
    "Weight limit": "+12000",
    "Inventory slots": "+12",
    "Max HP": "+1500",
    "Max MP": "+800",
    "Max CP": "+1500",
    "HP Recovery Potions' Effect": "+400",
    "MP Recovery Potions' Effect": "+120",
    "P. Def.": "+300",
    "M. Def.": "+3000",
    "Atk. Spd.": "+200",
    "Casting Spd.": "+200",
    "P. Critical Rate/ M. Skill Critical Rate": "+70",
    "Received Healing": "+20%",
    "Received damage": "-12%",
    "STR/ INT": "+6",
    "DEX/ WIT": "+6",
    "CON/ MEN": "+6",
  }
]

export const BLESSED_DRAGON_BELT_TRIGGER_V1 = [
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "-",
    "Effect": "-",
    "Duration": "-",
  },
  {
    "Probability": "When hit: 1%",
    "Effect": "Creates a barrier with 20,000 HP. Can be triggered up to 1 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 2%",
    "Effect": "Creates a barrier with 30,000 HP. Can be triggered up to 2 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 3%",
    "Effect": "Creates a barrier with 40,000 HP. Can be triggered up to 2 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 3%",
    "Effect": "Creates a barrier with 50,000 HP. Can be triggered up to 3 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 3%",
    "Effect": "Creates a barrier with 60,000 HP. Can be triggered up to 3 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 4%",
    "Effect": "Creates a barrier with 80,000 HP. Can be triggered up to 4 time(s).",
    "Duration": "10 sec.",
  },
  {
    "Probability": "When hit: 4%",
    "Effect": "Creates a barrier with 100,000 HP. Can be triggered up to 5 time(s).",
    "Duration": "10 sec.",
  },
]