export const ANTHARAS_DOLL_COMPOUND_V1 = [
  {
    "Value": "Lv. 1",
    "Probability": "-",
  },
  {
    "Value": "Lv. 2",
    "Probability": "7%",
  },
  {
    "Value": "Lv. 3",
    "Probability": "70%",
  },
  {
    "Value": "Lv. 4",
    "Probability": "50%",
  },
  {
    "Value": "Lv. 5",
    "Probability": "30%",
  },
  {
    "Value": "Lv. 6",
    "Probability": "30%",
  },
]

export const ANTHARAS_DOLL_V1 = [
  {
    "CON": "+1",
    "MEN": "+1",
    "Received Damage": "-2%",
    "Knockback Resistance": "+5%",
    "Shock Resistance": "+5%",
    "Pull Resistance": "+5%",
    "Imprisonment Resistance": "-",
    "Soulshot Damage Resistance": "+2%",
    "Spiritshot Damage Resistance": "+2%",
    "PvP damage": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "CON": "+2",
    "MEN": "+2",
    "Received Damage": "-5%",
    "Knockback Resistance": "+10%",
    "Shock Resistance": "+10%",
    "Pull Resistance": "+10%",
    "Imprisonment Resistance": "-",
    "Soulshot Damage Resistance": "+3%",
    "Spiritshot Damage Resistance": "+3%",
    "PvP damage": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "CON": "+3",
    "MEN": "+3",
    "Received Damage": "-10%",
    "Knockback Resistance": "+20%",
    "Shock Resistance": "+20%",
    "Pull Resistance": "+20%",
    "Imprisonment Resistance": "-",
    "Soulshot Damage Resistance": "+10%",
    "Spiritshot Damage Resistance": "+10%",
    "PvP damage": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "CON": "+4",
    "MEN": "+4",
    "Received Damage": "-12%",
    "Knockback Resistance": "+25%",
    "Shock Resistance": "+25%",
    "Pull Resistance": "+25%",
    "Imprisonment Resistance": "+10%",
    "Soulshot Damage Resistance": "+12%",
    "Spiritshot Damage Resistance": "+12%",
    "PvP damage": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "CON": "+5",
    "MEN": "+5",
    "Received Damage": "-15%",
    "Knockback Resistance": "+25%",
    "Shock Resistance": "+25%",
    "Pull Resistance": "+25%",
    "Imprisonment Resistance": "+20%",
    "Soulshot Damage Resistance": "+15%",
    "Spiritshot Damage Resistance": "+15%",
    "PvP damage": "-",
    "Acquired XP/ SP": "-",
  },
  {
    "CON": "+6",
    "MEN": "+6",
    "Received Damage": "-25%",
    "Knockback Resistance": "+30%",
    "Shock Resistance": "+30%",
    "Pull Resistance": "+30%",
    "Imprisonment Resistance": "+30%",
    "Soulshot Damage Resistance": "+20%",
    "Spiritshot Damage Resistance": "+20%",
    "PvP damage": "+2%",
    "Acquired XP/ SP": "+5%",
  },
]